const isEmpty = require("lodash.isempty");

/**
 * Takes an object with keys and true/false values
 * and returns an array of keys where they key is true
 *
 * @param {object} obj
 */
const formatCheckboxListFromApiValues = (obj = {}) =>
  isEmpty(obj) ? [] : Object.keys(obj).filter(key => obj[key]);

export { formatCheckboxListFromApiValues };
