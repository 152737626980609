const palette = {
  background: {
    default: "#f3f6f8"
  },
  primary: {
    dark: "#060025",
    main: "#2C1B4B",
    light: "#524171"
  },
  secondary: {
    dark: "#000",
    main: "#333",
    light: "#929191"
  },
  warning: {
    light: "#F6D89C",
    main: "#E9B550",
    dark: "#CE9423"
  },
  error: {
    light: "#EF8E80",
    main: "#D5351F",
    dark: "#A71805"
  },
  custom: {
    secondary: "#EEBFD9",
    blue: "#5677FC",
    green: "#42BD41",
    border: "rgba(0, 0, 0, .23)"
  }
};

export default palette;
