import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Auth0LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      size="large"
      data-cy="button-auth0-login"
      onClick={() => loginWithRedirect()}
    >
      {t("components.auth.auth_0_login_button.button")}
    </Button>
  );
};

export { Auth0LoginButton };
