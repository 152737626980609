import gql from "graphql-tag";

const GET_PROVIDER_TESTING_WORKSTATIONS = gql`
  query getTestingWorkstations {
    workstations
      @rest(
        type: "getTestingWorkstations"
        path: "/provider/get_workstations"
        endpoint: "v1"
      ) {
      workstations
    }
  }
`;

export { GET_PROVIDER_TESTING_WORKSTATIONS };
