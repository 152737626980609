import React from "react";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import { AuthorizedApolloProvider } from "./apolloClient";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";
import { Routes } from "./Routes";
import "./translations";
import { CssBaseline } from "@material-ui/core";
import { Auth0Provider } from "@auth0/auth0-react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "mapbox-gl/dist/mapbox-gl.css";
import { LanguageProvider } from "./contexts";

const App = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/provider/auth0callback`}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <AuthorizedApolloProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <BrowserRouter>
                <LanguageProvider>{() => <Routes />}</LanguageProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  );
};

export default App;
