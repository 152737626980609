import React, { useEffect, useState } from "react";
import { Box, Paper, Divider, Typography, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { string, object } from "yup";
import { useAdminHeader } from "../../contexts";
import {
  Sheet,
  SheetContent,
  BarcodeScanner,
  ButtonLoader
} from "../../components";
import { POST_PROVIDER_LABEL_SCAN } from "../../queries";
import { useMutation } from "@apollo/react-hooks";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { useYupValidation } from "../../hooks";
import { useTranslation } from "react-i18next";

const validationSchema = object({
  appointment_id: string()
    .required()
    .min(6)
    .trim()
    .label("Appointment ID")
});

const focusOnErrors = createDecorator();

const ProviderLabelScanner = () => {
  const { setTitle } = useAdminHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { validate } = useYupValidation();
  const { t } = useTranslation();

  const [previouslyScanned, setPreviouslyScanned] = useState([]);

  const [postProviderLabelScan, { loading }] = useMutation(
    POST_PROVIDER_LABEL_SCAN,
    {
      onCompleted: () => {
        enqueueSnackbar(t("label.label_scanner.success_api"), {
          variant: "success"
        });
      },
      onError: () => {
        enqueueSnackbar(t("label.label_scanner.error_api"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("label.label_scanner.title"));
  });

  useEffect(() => {
    return () => false;
  });

  /**
   * Send scan to API when previously scanned changes
   */
  useEffect(() => {
    if (previouslyScanned.length < 1) return;

    postProviderLabelScan({
      variables: {
        body: {
          appointment_id: previouslyScanned.slice(-1)[0]
        }
      }
    });
  }, [previouslyScanned]);

  /**
   * Submit scan
   *
   * @param {string} appointmentId
   */
  const submitScan = appointmentId => {
    setPreviouslyScanned(prev => {
      return prev.indexOf(appointmentId) === -1
        ? [...prev, appointmentId]
        : prev;
    });
  };

  /**
   * Handle scanned barcode
   *
   * @param {array} barcodes
   */
  const handleScan = ({ barcodes = [] }) => {
    const [barcode] = barcodes;

    if (!barcode.data) return;

    submitScan(barcode.data);
  };

  /**
   * Handle manual appointment id
   *
   * @param {object} data
   */
  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    submitScan(data.appointment_id);
  };

  return (
    <Sheet data-cy="provider-sample-scanner">
      <SheetContent>
        <Box mb={2}>
          <Paper>
            <Box p={2}>
              <BarcodeScanner
                handleScan={data => handleScan(data)}
                processing={loading}
              />
            </Box>
            <Divider variant="middle" />
            <Box p={2}>
              <Typography align="center" gutterBottom>
                {t("label.label_scanner.secondary_text")}
              </Typography>
              <Form
                onSubmit={onSubmit}
                initialValues={{}}
                decorators={[focusOnErrors]}
                render={({ handleSubmit, submitErrors: errors = {} }) => (
                  <form
                    data-cy="provider-appointment-scan"
                    onSubmit={handleSubmit}
                  >
                    <Field
                      name="appointment_id"
                      render={({ input }) => (
                        <TextField
                          {...input}
                          label={t("label.label_scanner.form.appointment_id")}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          error={!!errors.appointment_id}
                          helperText={errors.appointment_id}
                          inputProps={{
                            "data-lpignore": "true",
                            "data-cy": "input-appointment_id"
                          }}
                          type="tel"
                        />
                      )}
                    />
                    <ButtonLoader
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={loading}
                      fullWidth
                    >
                      {t("label.label_scanner.form.button")}
                    </ButtonLoader>
                  </form>
                )}
              />
            </Box>
            {previouslyScanned.length > 0 && (
              <Box p={2} textAlign="center">
                <Typography component="h2" variant="h6">
                  {t("label.label_scanner.success_text")}
                </Typography>
                {previouslyScanned.map(barcode => (
                  <Typography key={barcode}>{barcode}</Typography>
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      </SheetContent>
    </Sheet>
  );
};

export { ProviderLabelScanner };
