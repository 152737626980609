const BARCODES_GS1_AI_DEFINITIONS = {
  "01": {
    label_translation_key: "components.barcodes.definitions.gtin",
    value: "gtin"
  },
  "10": {
    label_translation_key: "components.barcodes.definitions.batch_lot_no",
    value: "lot_no"
  },
  "11": {
    label_translation_key: "components.barcodes.definitions.production_date",
    value: "production_date"
  },
  "15": {
    label_translation_key: "components.barcodes.definitions.best_before_date",
    value: "best_before_date"
  },
  "17": {
    label_translation_key: "components.barcodes.definitions.expiration_date",
    value: "expiration_date"
  },
  "21": {
    label_translation_key: "components.barcodes.definitions.serial_no",
    value: "serial_no"
  }
};

export { BARCODES_GS1_AI_DEFINITIONS };
