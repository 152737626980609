import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import { SignUpStart, SignUpInfo, SignUpFinish } from "./children";
import { LogoGGV } from "../../components";
import { useTranslation } from "react-i18next";

const useClasses = makeStyles(theme => {
  return {
    quote: {
      color: theme.palette.common.white
    },
    quoteWrapper: {
      background: theme.palette.primary.main,
      height: "100%"
    },
    fullHeight: {
      minHeight: "100vh"
    },
    gridContainerCol: {
      [theme.breakpoints.up("lg")]: {
        minHeight: "400px"
      }
    }
  };
});

const SignUp = () => {
  const classes = useClasses();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.fullHeight}
    >
      <Grid item xs={11} sm={10} md={8}>
        <Grid item>
          <Box p={2}>
            <Link href="https://gogettested.com" target="_blank" rel="noopener">
              <LogoGGV />
            </Link>
          </Box>
        </Grid>
        <Paper>
          <Grid container justify="space-between">
            <Grid item xs={12} md={6} className={classes.gridContainerCol}>
              <Switch>
                <Route path="/signup/finish" exact component={SignUpFinish} />
                <Route
                  path="/signup/provider-info"
                  exact
                  component={SignUpInfo}
                />
                <Route path="/signup" component={SignUpStart} />
              </Switch>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridContainerCol}>
              <Grid
                container
                className={classes.quoteWrapper}
                justify="center"
                alignItems="center"
              >
                <Grid item xs>
                  <Box p={4} className={classes.quote}>
                    <Typography color="inherit" variant="h4">
                      &ldquo;{t("sign_up.sign_up.quote")}&rdquo;
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="overline">Dr. Samad Khan</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export { SignUp };
