import grey from "@material-ui/core/colors/grey";

const typography = {
  lineHeight: 1.4,
  h1: {
    fontSize: "1.383rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  h2: {
    fontSize: "1.296rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  h3: {
    fontSize: "1.215rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  h4: {
    fontSize: "1.138rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  h5: {
    fontSize: "1.067rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.4
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: 1.4,
    fontWeight: 600
  },
  subtitle2: {
    fontSize: ".875rem",
    lineHeight: 1.4,
    fontWeight: 400
  },
  body1: {
    lineHeight: 1.4,
    fontSize: ".875rem",
    color: grey[700]
  },
  body2: {
    lineHeight: 1.4
  },
  button: {},
  caption: {
    fontSize: ".875rem",
    color: grey[700]
  },
  overline: {},
  small: {
    fontSize: ".75rem"
  },
  iconInline: {
    verticalAlign: "text-bottom",
    fontSize: "1rem",
    marginRight: ".25rem"
  }
};

export default typography;
