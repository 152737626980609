import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  TextField,
  Typography
} from "@material-ui/core";
import { SearchLocationInput } from "./SearchLocationInput";
import { useSignup } from "../../../contexts";

const SignUpStart = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { signupData, setSignupData } = useSignup();
  const { public_name, org_name } = signupData;

  const submitForm = () => history.push("/signup/provider-info");

  const handleNameChange = e => {
    setSignupData({ org_name: e.target.value });
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        direction="column"
        style={{ height: "100%" }}
      >
        <Box p={4}>
          <Typography
            variant="h6"
            component="h1"
            data-cy={`page-title-${t("signup-start-title")}`}
          >
            <strong>{t("sign_up.start.title")}</strong>
          </Typography>
          <Typography>{t("sign_up.start.secondary_text")}</Typography>
          <form>
            <Box my={2}>
              <SearchLocationInput />
              <TextField
                data-cy="input-legal-name"
                variant="outlined"
                margin="normal"
                value={org_name}
                onChange={handleNameChange}
                fullWidth
                label={t("sign_up.start.form.legal_name")}
              />
            </Box>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  data-cy="button-next"
                  color="primary"
                  variant="contained"
                  disabled={!org_name || !public_name}
                  onClick={submitForm}
                >
                  {t("sign_up.start.button")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box>
          <Divider />
          <Box px={4} py={2}>
            <Typography>
              <Trans i18nKey="sign_up.start.sign_in">
                Already have an account? <Link href="/provider">Sign In</Link>
              </Trans>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export { SignUpStart };
