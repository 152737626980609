import React from "react";
import { Typography } from "@material-ui/core";
import { ButtonLink } from "./ButtonLink";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChatTeaser = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Typography color="inherit">
      {t("components.chat_teaser.title")}{" "}
      <ButtonLink
        onClick={() => history.push({ pathname: `/chat` })}
        color="inherit"
        label={t("components.chat_teaser.button_label")}
      />
    </Typography>
  );
};

export { ChatTeaser };
