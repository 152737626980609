import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Provider,
  ProviderLogin,
  ProviderLogout,
  ProviderAppointmentProcess,
  ProviderLabelScanner,
  ProviderPatientSearch,
  ProviderVialSearch
} from "./views/Provider";
import { SignUp } from "./views/SignUp";
import { AdminHeaderProvider, SignupProvider } from "./contexts";
import {
  PROVIDER_ROLES,
  PROVIDER_SESSION_AUTH,
  AUTH0_ROLE_KEY
} from "./constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useProviderAuth, usePermissions } from "./hooks";
import { AuthNoPermission, Loader } from "./components";

const ProviderRoute = ({ children, role = PROVIDER_ROLES.ADMIN, ...rest }) => {
  const { hasPermission } = usePermissions();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { decodedToken } = useProviderAuth();

  const [gettingToken, setGettingToken] = useState(true);

  /** Get Auth0 access token on mount */
  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      sessionStorage.setItem(PROVIDER_SESSION_AUTH, token);
      setGettingToken(false);
    };

    // Get jwt token Auth0 if necessary
    if (isAuthenticated) {
      getToken();
    } else {
      setGettingToken(false);
    }
  }, []);

  if (gettingToken) return <Loader />;

  if (!sessionStorage.getItem(PROVIDER_SESSION_AUTH))
    return <Redirect to={{ pathname: `/provider/login` }} />;

  return (
    <Route
      {...rest}
      render={() => (
        <AdminHeaderProvider authenticated={true} user={user}>
          {!role || hasPermission(decodedToken[AUTH0_ROLE_KEY], role) ? (
            children
          ) : (
            <AuthNoPermission />
          )}
        </AdminHeaderProvider>
      )}
    />
  );
};

const ProviderLoginCallback = () => {
  const { isLoading } = useAuth0();

  if (isLoading) return null;

  return <Redirect to={{ pathname: `/provider` }} />;
};

const Routes = () => {
  return (
    <Switch>
      {/* Provider Routes */}

      <ProviderRoute
        exact
        path={`/patient/search`}
        role={PROVIDER_ROLES.CLINICAL_PROVIDER}
      >
        <ProviderPatientSearch />
      </ProviderRoute>

      <ProviderRoute
        exact
        path={`/vial/scanner`}
        role={PROVIDER_ROLES.CLINICAL_PROVIDER}
      >
        <ProviderVialSearch />
      </ProviderRoute>

      <ProviderRoute exact path={`/provider/appointment-process`}>
        <ProviderAppointmentProcess />
      </ProviderRoute>

      <ProviderRoute
        exact
        path={`/provider/label/scanner`}
        role={PROVIDER_ROLES.CLINICAL_PROVIDER}
      >
        <ProviderLabelScanner />
      </ProviderRoute>

      <Route path={`/provider/login`} exact component={ProviderLogin} />
      <Route path={`/provider/logout`} exact component={ProviderLogout} />

      <Route
        path={`/provider/auth0callback`}
        exact
        component={ProviderLoginCallback}
      />

      <ProviderRoute path={`/provider`} role={PROVIDER_ROLES.CARE_PROVIDER}>
        <Provider />
      </ProviderRoute>

      {/* Public Routes */}

      <Route path={`/signup`}>
        <SignupProvider>
          <SignUp />
        </SignupProvider>
      </Route>

      <Redirect to={`/provider`} />
    </Switch>
  );
};

export { Routes };
