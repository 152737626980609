import React from "react";
import { Box, Grid, Chip, Typography } from "@material-ui/core";
import {
  CakeOutlined,
  AlternateEmailOutlined,
  LocalPhoneOutlined,
  HomeOutlined
} from "@material-ui/icons";
import { PatientAddress } from "./PatientAddress";
import { useTranslation } from "react-i18next";

const PatientContact = ({ patient }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CakeOutlined fontSize="small" />
        </Grid>
        <Grid item xs>
          <Typography>{patient.dob}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <AlternateEmailOutlined fontSize="small" />
        </Grid>
        <Grid item>
          <Typography>{patient.email}</Typography>
        </Grid>
        <Grid item>
          {patient.email_verified && (
            <Chip
              label={t("components.patient.contact.email_status")}
              color="primary"
              variant="outlined"
              size="small"
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <LocalPhoneOutlined fontSize="small" />
        </Grid>
        <Grid item>
          <Typography>{patient.phone_number}</Typography>
        </Grid>
        <Grid item>
          {patient.phone_number_verified && (
            <Chip
              label={t("components.patient.contact.phone_status")}
              color="primary"
              variant="outlined"
              size="small"
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <HomeOutlined fontSize="small" />
        </Grid>
        <Grid item xs>
          <PatientAddress
            street={patient.addr1}
            complement={patient.addr2}
            city={patient.city}
            state={patient.st}
            zip={patient.zip}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { PatientContact };
