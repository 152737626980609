import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { object, string } from "yup";
import { useYupValidation } from "../../hooks";
import { validDateOfBirth } from "../../utils";
import { MaskedInputDate } from "../../components";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const validationSchema = object({
  dob: string()
    .test("Valid date of birth", "Date of birth is invalid", value =>
      value ? validDateOfBirth(value) : false
    )
    .label("Date of birth")
});

const PatientAuthByDob = ({ handleOnSubmit, children }) => {
  const { validate } = useYupValidation();
  const { t } = useTranslation();

  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    handleOnSubmit(data);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      decorators={[focusOnErrors]}
      render={({ handleSubmit, submitErrors: errors = {} }) => (
        <form data-cy="patient-auth-by-dob" onSubmit={handleSubmit}>
          <Field
            name="dob"
            render={({ input }) => (
              <TextField
                {...input}
                label={t("components.auth.patient_auth_by_dob.form.verify_dob")}
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.dob}
                helperText={errors.dob}
                InputProps={{
                  inputComponent: MaskedInputDate,
                  inputProps: {
                    "data-lpignore": "true",
                    "data-cy": "input-dob"
                  }
                }}
              />
            )}
          />
          {children}
        </form>
      )}
    />
  );
};

export { PatientAuthByDob };
