import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const MaskedInputDate = ({ inputRef, onChange, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      format="##/##/####"
      placeholder="MM/DD/YYYY"
    />
  );
};

MaskedInputDate.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export { MaskedInputDate };
