import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TextField,
  Grid,
  Typography,
  Button,
  Hidden,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Drawer,
  makeStyles,
  Divider
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useAdminHeader } from "../../contexts";
import {
  Sheet,
  SheetContent,
  ButtonLoader,
  BarcodeScanner
} from "../../components";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { useMutation } from "@apollo/react-hooks";
import { POST_PROVIDER_ADMIN_GENERAL_SEARCH } from "../../queries";
import { AdminPatientCard } from "../../components/Admin/AdminPatientCard";
import { PatientContact, PatientAppointment } from "../../components/Patient";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const useClasses = makeStyles(theme => ({
  clickableTableRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100]
    }
  },
  drawerPaper: {
    width: theme.breakpoints.values.sm
  }
}));

const DrawerSubheader = ({ label, showDivider = true }) => {
  return (
    <Box mt={2} mb={1}>
      {showDivider ? (
        <Box mb={2}>
          <Divider />
        </Box>
      ) : null}
      <Typography component="h2" variant="h6" paragraph>
        <strong>{label}</strong>
      </Typography>
    </Box>
  );
};

const ProviderVialSearch = () => {
  const classes = useClasses();
  const { setTitle } = useAdminHeader();
  const { t } = useTranslation();

  const [results, setResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setTitle(t("patient.vial_search.title"));
  });

  const [postGeneralSearch, { loading: generalSearchLoading }] = useMutation(
    POST_PROVIDER_ADMIN_GENERAL_SEARCH,
    {
      onCompleted: ({ search }) => {
        if (showError) setShowError(false);
        setResults([]);
        if (search.results) setResults(search.results);
        if (!search || search.status === "failed") setShowError(true);
      }
    }
  );

  const handleClearSearch = form => {
    form.reset();
    setResults([]);
    setShowError(false);
  };

  const validateForm = async data => {
    postGeneralSearch({
      variables: {
        body: {
          appointment_date: "",
          appointment_id: "",
          auth_token: "",
          dob: "",
          email: "",
          first_name: "",
          group_code: "",
          last_name: "",
          location_id: "",
          middle_name: "",
          phone_number: "",
          vial_id: "",
          ...data
        }
      }
    });
  };

  const handleResultClick = appointmentId => {
    const [thePatient] = results.filter(
      result => result.appointment_id === appointmentId
    );
    if (thePatient) {
      setSelectedPatient(thePatient);
    } else {
      setSelectedPatient();
    }
  };

  const handleScan = ({ barcodes = [] }) => {
    const [barcode] = barcodes;

    if (!barcode.data) return;
    validateForm({ vial_id: barcode.data });
  };
  return (
    <>
      <Sheet data-cy="provider-vial">
        <SheetContent>
          <Box mb={2}>
            <Paper>
              <Box p={2}>
                <Form
                  onSubmit={validateForm}
                  decorators={[focusOnErrors]}
                  render={({
                    handleSubmit,
                    form,
                    submitErrors: errors = {}
                  }) => (
                    <form
                      data-cy="provider-admin-vial-search"
                      onSubmit={handleSubmit}
                    >
                      <BarcodeScanner
                        handleScan={data => handleScan(data)}
                        processing={generalSearchLoading}
                      />
                      <Box my={2}>
                        <Divider variant="middle" />
                      </Box>
                      <Typography align="center" paragraph>
                        {t("patient.vial_search.secondary_text")}
                      </Typography>
                      <Field
                        name="vial_id"
                        render={({ input }) => (
                          <TextField
                            {...input}
                            label={t("patient.vial_search.form.vial_id")}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={!!errors.vial_id}
                            helperText={errors.vial_id}
                            inputProps={{
                              "data-lpignore": "true",
                              "data-cy": "input-vial_id"
                            }}
                            autoFocus
                          />
                        )}
                      />
                      <Box mt={2}>
                        <Grid container spacing={2} justify="center">
                          <Grid item>
                            <Button
                              type="button"
                              onClick={() => handleClearSearch(form)}
                            >
                              {t("patient.vial_search.button_clear")}
                            </Button>
                          </Grid>
                          <Grid item xs md={3}>
                            <ButtonLoader
                              type="submit"
                              color="primary"
                              variant="contained"
                              fullWidth
                              loading={generalSearchLoading}
                            >
                              {t("patient.vial_search.button_search")}
                            </ButtonLoader>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                />
              </Box>
            </Paper>
          </Box>
        </SheetContent>
      </Sheet>

      {results.length > 0 ? (
        <Sheet data-cy="provider-patient-search" maxWidth="lg">
          <SheetContent>
            <Box mb={2}>
              {results.length > 0 ? (
                <>
                  <Hidden mdUp>
                    {results.map(patient => (
                      <Box my={2} key={`patient-${patient.patient_id}`}>
                        <AdminPatientCard patient={patient} />
                      </Box>
                    ))}
                  </Hidden>
                  <Hidden smDown>
                    <Box my={2}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {t("patient.vial_search.table.apt_id")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.patient_id")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.name")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.dob")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.phone")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.email")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.vial_id")}
                              </TableCell>
                              <TableCell>
                                {t("patient.vial_search.table.apt_status")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {results.map(patient => (
                              <TableRow
                                key={`patient-${patient.patient_id}`}
                                onClick={() =>
                                  handleResultClick(patient.appointment_id)
                                }
                                className={classes.clickableTableRow}
                              >
                                <TableCell>{patient.appointment_id}</TableCell>
                                <TableCell>{patient.patient_id}</TableCell>
                                <TableCell>
                                  {patient.last_name}, {patient.first_name}
                                </TableCell>
                                <TableCell>{patient.dob}</TableCell>
                                <TableCell>{patient.phone_number}</TableCell>
                                <TableCell>{patient.email}</TableCell>
                                <TableCell>{patient.vial_id || ""}</TableCell>
                                <TableCell>
                                  {patient.appointment_status ===
                                    "test_completed" && (
                                    <Chip
                                      label="Completed"
                                      color="primary"
                                      size="small"
                                    />
                                  )}
                                  {patient.appointment_status ===
                                    "scheduled" && (
                                    <Chip label="Scheduled" size="small" />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    {selectedPatient ? (
                      <Drawer
                        anchor="right"
                        open={true}
                        onClose={() => setSelectedPatient()}
                        classes={{
                          paper: classes.drawerPaper
                        }}
                      >
                        <Box p={2} pt={0}>
                          <DrawerSubheader
                            label={`${selectedPatient.last_name}, ${selectedPatient.first_name}`}
                            showDivider={false}
                          />
                          <PatientContact patient={selectedPatient} />
                          <DrawerSubheader label="Appointment" />
                          <PatientAppointment patient={selectedPatient} />
                        </Box>
                      </Drawer>
                    ) : null}
                  </Hidden>
                </>
              ) : null}
              {showError && (
                <Alert style={{ marginTop: "50px" }} severity="error">
                  {t("patient.vial_search.error")}
                </Alert>
              )}
            </Box>
          </SheetContent>
        </Sheet>
      ) : null}
    </>
  );
};

export { ProviderVialSearch };
