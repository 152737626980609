import React from "react";
import { Grid, Box } from "@material-ui/core";
import { BottomDrawer, ChatTeaser, ButtonLoader } from ".";

const FormActionDrawer = ({
  handleNext = () => {},
  setDrawerClearance,
  nextButtonLabel = "Next",
  loading = false
}) => {
  return (
    <BottomDrawer setClearance={setDrawerClearance}>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs>
          <ButtonLoader
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            data-cy="button-next"
            onClick={handleNext}
            loading={loading}
          >
            {nextButtonLabel}
          </ButtonLoader>
        </Grid>
      </Grid>
      <Box mt={2} textAlign="center">
        <ChatTeaser />
      </Box>
    </BottomDrawer>
  );
};

export { FormActionDrawer };
