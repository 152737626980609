import React from "react";
import { Typography } from "@material-ui/core";

const PatientAddress = ({ street, complement, city, state, zip }) => {
  return (
    <Typography>
      {street}, {complement && `${complement},`}
      {city}, {state} {zip}
    </Typography>
  );
};

export { PatientAddress };
