const overrides = {
  MuiSkeleton: {
    text: {
      marginTop: ".5rem",
      marginBottom: ".5rem"
    }
  },
  MuiTableCell: {
    head: {
      lineHeight: "1.4"
    },
    sizeSmall: {
      padding: ".5rem"
    }
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: ".5rem",
      marginBottom: ".5rem"
    }
  },
  MuiButton: {
    sizeLarge: {
      padding: "8px 14px"
    }
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: "inherit"
      },
      "&$error": {
        color: "inherit"
      }
    }
  }
};

export default overrides;
