import gql from "graphql-tag";

const POST_NEW_ORGANIZATION_REQUEST = gql`
  mutation postNewOrganizationRequest($body: Object) {
    organizationRequest(body: $body)
      @rest(
        type: "postSignUp"
        path: "/management/create_new_organisation_request"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      status
      results
    }
  }
`;

export { POST_NEW_ORGANIZATION_REQUEST };
