import React, { createContext, useState } from "react";
import { Header } from "../components";
import { AdminHeader } from "../components/Admin";

const HeaderContext = createContext();

const AdminHeaderProvider = ({ children, authenticated = false, user }) => {
  const [title, setTitle] = useState("");
  const [headerHeight, setHeaderHeight] = useState(0);

  if (!authenticated) return null;

  return (
    <HeaderContext.Provider value={{ setTitle, headerHeight }}>
      <AdminHeader title={title} setHeight={setHeaderHeight} user={user} />
      <HeaderContext.Consumer>{() => children}</HeaderContext.Consumer>
    </HeaderContext.Provider>
  );
};

const useAdminHeader = () => React.useContext(HeaderContext);

export { AdminHeaderProvider, useAdminHeader };
