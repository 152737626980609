import React, { useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Drawer, Container, Box, makeStyles } from "@material-ui/core";

const useClasses = makeStyles(theme => ({
  drawerPaper: {
    overflowY: "visible",
    backgroundColor: theme.palette.background.default
  }
}));

const BottomDrawer = ({ children, setClearance }) => {
  const classes = useClasses();
  const drawerRef = useRef(null);

  useLayoutEffect(() => {
    if (drawerRef.current) {
      setClearance(drawerRef.current.clientHeight);
    }
  });

  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      PaperProps={{ className: classes.drawerPaper }}
    >
      <Container maxWidth="xs" disableGutters>
        <Box p={2} ref={drawerRef}>
          {children}
        </Box>
      </Container>
    </Drawer>
  );
};

BottomDrawer.propTypes = {
  setClearance: PropTypes.func.isRequired
};

export { BottomDrawer };
