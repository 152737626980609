import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { IntroText, FormActionDrawer, Loader } from "../../../components";
import createDecorator from "final-form-focus";
import { useAdminHeader } from "../../../contexts";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import {
  PROVIDER_SESSION_LOCATION,
  PROVIDER_SESSION_AUTH
} from "../../../constants";
import { Form, Field } from "react-final-form";
import { string, object, boolean } from "yup";
import { useYupValidation } from "../../../hooks";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const validationSchema = object({
  no_adverse_reactions: boolean(),
  appointment_notes: string()
    .trim()
    .label("Appointment notes")
    .when("no_adverse_reactions", {
      is: false,
      then: string()
        .required()
        .label("Appointment notes")
    }),
  injection_site: string()
    .required()
    .label("Intramuscular injection site")
});

const INJECTION_SITE_OPTIONS = [
  {
    value: "left_arm",
    label_translation_key: "appointment.notes.injection_site_options.left_arm"
  },
  {
    value: "right_arm",
    label_translation_key: "appointment.notes.injection_site_options.right_arm"
  },
  {
    value: "left_thigh",
    label_translation_key: "appointment.notes.injection_site_options.left_thigh"
  },
  {
    value: "right_thigh",
    label_translation_key:
      "appointment.notes.injection_site_options.right_thigh"
  }
];

const ProviderAppointmentNotes = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { validate } = useYupValidation();
  const { t } = useTranslation();

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;
        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.notes.error"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.notes.title"));
  });

  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: appointment.next_action,
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          appointment_notes: data.appointment_notes,
          injection_site: data.injection_site,
          no_adverse_reactions: data.no_adverse_reactions,
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              no_adverse_reactions: true,
              appointment_notes: "",
              injection_site: "right_arm"
            }}
            decorators={[focusOnErrors]}
            render={({ handleSubmit, submitErrors: errors = {}, values }) => (
              <form
                data-cy="provider-appointment-notes"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2} direction="column">
                  {/* <Grid item xs>
                    <Field
                      name="appointment_notes"
                      render={({ input }) => (
                        <TextField
                          {...input}
                          label="Observations and notes"
                          fullWidth
                          multiline
                          rows={4}
                          margin="normal"
                          variant="outlined"
                          error={!!errors.appointment_notes}
                          helperText={errors.appointment_notes}
                          inputProps={{
                            "data-lpignore": "true",
                            "data-cy": "input-appointment_notes"
                          }}
                          type="text"
                        />
                      )}
                    />
                    <Field
                      name="no_adverse_reactions"
                      type="checkbox"
                      render={({ input }) => (
                        <FormControlLabel
                          data-cy="input-no_adverse_reactions"
                          control={<Checkbox {...input} color="primary" />}
                          label="No adverse reactions observed"
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs>
                    <Field
                      name="injection_site"
                      type="radio"
                      render={({ input }) => (
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {t("appointment.notes.form.injection_site")}
                          </FormLabel>
                          <RadioGroup aria-label="injection site" {...input}>
                            <Box pt={1}>
                              <Grid container spacing={2}>
                                {INJECTION_SITE_OPTIONS.map((site, index) => (
                                  <Grid item xs={6} key={`site-${index}`}>
                                    <FormControlLabel
                                      data-cy={`input-${site.value}`}
                                      value={site.value}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={
                                            values.injection_site === site.value
                                          }
                                        />
                                      }
                                      label={t(site.label_translation_key)}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </RadioGroup>
                          {!!errors.injection_site && (
                            <FormHelperText error={true}>
                              {errors.injection_site}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <FormActionDrawer
                  setDrawerClearance={setDrawerClearance}
                  nextButtonLabel={t("appointment.notes.button")}
                  loading={loading}
                />
              </form>
            )}
          />
        </>
      )}
    </>
  );
};

export { ProviderAppointmentNotes };
