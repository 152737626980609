import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TextField,
  Grid,
  Typography,
  Button,
  Hidden,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  makeStyles,
  Divider
} from "@material-ui/core";
import { useAdminHeader } from "../../contexts";
import {
  Sheet,
  SheetContent,
  MaskedInputDate,
  MaskedInputPhone,
  ButtonLoader,
  StatusChip
} from "../../components";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { useMutation } from "@apollo/react-hooks";
import { POST_PROVIDER_ADMIN_GENERAL_SEARCH } from "../../queries";
import { AdminPatientCard } from "../../components/Admin/AdminPatientCard";
import {
  PatientContact,
  PatientAppointment,
  PatientConsultation
} from "../../components/Patient";
import { SERVICE_CODE } from "../../constants";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const useClasses = makeStyles(theme => ({
  clickableTableRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100]
    }
  },
  drawerPaper: {
    width: theme.breakpoints.values.sm
  }
}));

const DrawerSubheader = ({ label, showDivider = true }) => {
  return (
    <Box mt={2} mb={1}>
      {showDivider ? (
        <Box mb={2}>
          <Divider />
        </Box>
      ) : null}
      <Typography component="h2" variant="h6" paragraph>
        <strong>{label}</strong>
      </Typography>
    </Box>
  );
};

const ProviderPatientSearch = () => {
  const classes = useClasses();
  const { setTitle } = useAdminHeader();
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();

  useEffect(() => {
    setTitle(t("patient.patient_search.title"));
  });

  const [postGeneralSearch, { loading: generalSearchLoading }] = useMutation(
    POST_PROVIDER_ADMIN_GENERAL_SEARCH,
    {
      onCompleted: ({ search }) => {
        if (search.results) setResults(search.results);
      }
    }
  );

  const handleClearSearch = form => {
    form.reset();
    setResults([]);
  };

  const validateForm = async data => {
    postGeneralSearch({
      variables: {
        body: {
          appointment_date: "",
          appointment_id: "",
          auth_token: "",
          dob: "",
          email: "",
          first_name: "",
          group_code: "",
          last_name: "",
          location_id: "",
          middle_name: "",
          phone_number: "",
          ...data
        }
      }
    });
  };

  const handleResultClick = appointmentId => {
    const [thePatient] = results.filter(
      result => result.appointment_id === appointmentId
    );

    if (thePatient) {
      setSelectedPatient(thePatient);
    } else {
      setSelectedPatient();
    }
  };

  return (
    <Sheet data-cy="provider-patient-search" maxWidth="lg">
      <SheetContent>
        <Box mb={2}>
          <Paper>
            <Box p={2}>
              <Form
                onSubmit={validateForm}
                decorators={[focusOnErrors]}
                render={({ handleSubmit, form, submitErrors: errors = {} }) => (
                  <>
                    <form
                      data-cy="provider-admin-patient-search"
                      onSubmit={handleSubmit}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name="first_name"
                            render={({ input }) => (
                              <TextField
                                {...input}
                                label={t(
                                  "patient.patient_search.form.first_name"
                                )}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                error={!!errors.first_name}
                                helperText={errors.first_name}
                                inputProps={{
                                  "data-lpignore": "true",
                                  "data-cy": "input-first_name"
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="last_name"
                            render={({ input }) => (
                              <TextField
                                {...input}
                                label={t(
                                  "patient.patient_search.form.last_name"
                                )}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                error={!!errors.last_name}
                                helperText={errors.last_name}
                                inputProps={{
                                  "data-lpignore": "true",
                                  "data-cy": "input-last_name"
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="dob"
                            render={({ input }) => (
                              <TextField
                                {...input}
                                data-cy="input-login-dob"
                                label={t("patient.patient_search.form.dob")}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                error={!!errors.dob}
                                helperText={errors.dob}
                                InputProps={{
                                  inputComponent: MaskedInputDate
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="phone_number"
                            render={({ input }) => (
                              <TextField
                                {...input}
                                data-cy="input-login-phone"
                                margin="none"
                                variant="outlined"
                                label={t(
                                  "patient.patient_search.form.phone_number"
                                )}
                                fullWidth
                                error={!!errors.phone_number}
                                helperText={errors.phone_number}
                                InputProps={{
                                  inputComponent: MaskedInputPhone,
                                  inputProps: {
                                    "data-lpignore": "true",
                                    "data-cy": "input-email"
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="email"
                            render={({ input }) => (
                              <TextField
                                {...input}
                                label={t("patient.patient_search.form.email")}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                error={!!errors.email}
                                helperText={errors.email}
                                inputProps={{
                                  "data-lpignore": "true",
                                  "data-cy": "input-email"
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Grid container spacing={2} justify="center">
                          <Grid item>
                            <Button
                              type="button"
                              onClick={() => handleClearSearch(form)}
                            >
                              {t("patient.patient_search.button_clear")}
                            </Button>
                          </Grid>
                          <Grid item xs md={3}>
                            <ButtonLoader
                              type="submit"
                              color="primary"
                              variant="contained"
                              fullWidth
                              loading={generalSearchLoading}
                            >
                              {t("patient.patient_search.button_search")}
                            </ButtonLoader>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </>
                )}
              />
            </Box>
          </Paper>
          {results.length > 0 ? (
            <>
              <Hidden mdUp>
                {results.map(patient => (
                  <Box my={2} key={`patient-${patient.appointment_id}`}>
                    <AdminPatientCard patient={patient} />
                  </Box>
                ))}
              </Hidden>
              <Hidden smDown>
                <Box my={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t("patient.patient_search.table.apt_id")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.name")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.dob")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.phone")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.email")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.service")}
                          </TableCell>
                          <TableCell>
                            {t("patient.patient_search.table.apt_status")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results.map(patient => (
                          <TableRow
                            key={`patient-${patient.appointment_id}`}
                            onClick={() =>
                              handleResultClick(patient.appointment_id)
                            }
                            className={classes.clickableTableRow}
                          >
                            <TableCell>{patient.appointment_id}</TableCell>
                            <TableCell>
                              {patient.last_name}, {patient.first_name}
                            </TableCell>
                            <TableCell>{patient.dob}</TableCell>
                            <TableCell>{patient.phone_number}</TableCell>
                            <TableCell>{patient.email}</TableCell>
                            <TableCell>
                              {SERVICE_CODE[patient.service_code] &&
                              SERVICE_CODE[patient.service_code].label
                                ? SERVICE_CODE[patient.service_code].label
                                : ""}
                            </TableCell>
                            <TableCell>
                              <StatusChip
                                status={patient.appointment_status}
                                size="small"
                                variant="outlined"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {selectedPatient ? (
                  <Drawer
                    anchor="right"
                    open={true}
                    onClose={() => setSelectedPatient()}
                    classes={{
                      paper: classes.drawerPaper
                    }}
                  >
                    <Box p={2} pt={0}>
                      <DrawerSubheader
                        label={`${selectedPatient.last_name}, ${selectedPatient.first_name}`}
                        showDivider={false}
                      />
                      <PatientContact patient={selectedPatient} />
                      <DrawerSubheader label="Appointment" />
                      <PatientAppointment patient={selectedPatient} />
                      <DrawerSubheader label="Notes" />
                      <PatientConsultation
                        appointmentId={selectedPatient.appointment_id}
                        pastConsultations={selectedPatient.consultations}
                        serviceCode={selectedPatient.service_code}
                      />
                    </Box>
                  </Drawer>
                ) : null}
              </Hidden>
            </>
          ) : null}
        </Box>
      </SheetContent>
    </Sheet>
  );
};

export { ProviderPatientSearch };
