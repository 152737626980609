import React, { useState } from "react";
import { Menu, MenuItem, Button, makeStyles } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { LANGUAGES } from "../../constants";
import PropTypes from "prop-types";

const useClasses = makeStyles(theme => ({
  inverse: {
    color: theme.palette.common.white
  }
}));

const LanguageToggle = ({
  language,
  handleToggleLanguage,
  inverse = false
}) => {
  const classes = useClasses();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (e, lang) => {
    handleCloseMenu();
    handleToggleLanguage(e, lang.key);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        startIcon={<Language />}
        size="small"
        className={inverse ? classes.inverse : null}
      >
        {language}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        onClose={handleCloseMenu}
        open={!!anchorEl}
      >
        {LANGUAGES.map(languageItem => (
          <MenuItem
            key={`language-${languageItem.key}`}
            onClick={e => handleChangeLanguage(e, languageItem)}
            selected={languageItem.key === language}
          >
            {languageItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LanguageToggle.propTypes = {
  language: PropTypes.string.isRequired,
  handleToggleLanguage: PropTypes.func.isRequired,
  inverse: PropTypes.bool
};

export { LanguageToggle };
