import React from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";

const useClasses = makeStyles({
  grid: {
    height: "100vh",
    position: "absolute",
    top: "0",
    left: "0"
  }
});

const Loader = () => {
  const classes = useClasses();

  return (
    <Grid
      className={classes.grid}
      container
      justify="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
};

export { Loader };
