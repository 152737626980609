import React, { useState } from 'react';

const SignupContext = React.createContext();

const SignupProvider = ({ children }) => {
  const [signupData, updateSignupData] = useState({});

  const setSignupData = (newData) => {
    updateSignupData(oldData => ({ ...oldData, ...newData }));
  };

  return (
    <SignupContext.Provider value={{ signupData, setSignupData }}>
      <SignupContext.Consumer>{() => children}</SignupContext.Consumer>
    </SignupContext.Provider>
  );
};

const useSignup = () => React.useContext(SignupContext);

export { SignupProvider, useSignup };
