import React from "react";
import PropTypes from "prop-types";
import { Button, Box, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  loading: {
    ...theme.position.absoluteCenter,
    lineHeight: "0"
  }
}));

const ButtonLoader = ({ loading = false, children, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <Button {...props} disabled={loading || disabled}>
      {children}
      {loading && (
        <Box className={classes.loading}>
          <CircularProgress size={24} />
        </Box>
      )}
    </Button>
  );
};

ButtonLoader.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export { ButtonLoader };
