import {
  SERVICE_COVID_19_VACCINE_PFIZER_1,
  SERVICE_COVID_19_VACCINE_PFIZER_2,
  SERVICE_COVID_19_VACCINE_MODERNA_1,
  SERVICE_COVID_19_VACCINE_MODERNA_2,
  SERVICE_COVID_19_VAX,
  SERVICE_COVID_19_VACCINE_JNJ
} from "../constants";

const VAX_SERVICE_CODES = [
  SERVICE_COVID_19_VACCINE_PFIZER_1,
  SERVICE_COVID_19_VACCINE_PFIZER_2,
  SERVICE_COVID_19_VACCINE_MODERNA_1,
  SERVICE_COVID_19_VACCINE_MODERNA_2,
  SERVICE_COVID_19_VAX,
  SERVICE_COVID_19_VACCINE_JNJ
];

const isVaxAppointment = appointmentCodes =>
  appointmentCodes && appointmentCodes.length > 0
    ? appointmentCodes.some(code => VAX_SERVICE_CODES.includes(code))
    : false;

export { isVaxAppointment };
