import React from "react";
import { Box, Container } from "@material-ui/core";

const Sheet = ({ children, className, maxWidth = "xs", ...rest }) => {
  return (
    <Container maxWidth={maxWidth} disableGutters>
      <Box className={`${className}`} p={2} {...rest}>
        {children}
      </Box>
    </Container>
  );
};

const SheetHeader = ({ children, className }) => {
  return <Box className={`${className}`}>{children}</Box>;
};

const SheetContent = ({ children, className }) => {
  return <Box className={`${className}`}>{children}</Box>;
};

export { Sheet, SheetContent, SheetHeader };
