import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useMutation } from "@apollo/react-hooks";
import { useAdminHeader } from "../../../contexts";
import { Camera, FormActionDrawer } from "../../../components";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import { hasGetUserMedia } from "../../../utils";
import {
  PROVIDER_SESSION_AUTH,
  PROVIDER_SESSION_LOCATION
} from "../../../constants";
import { Trans, useTranslation } from "react-i18next";
import { useYupValidation } from "../../../hooks";
import { Form, Field } from "react-final-form";
import { string, object } from "yup";
import createDecorator from "final-form-focus";

const focusOnErrors = createDecorator();

const validationSchema = object({
  test_result: string()
    .required()
    .label("Test result")
});

const TEST_RESULT_OPTIONS = [
  {
    value: "positive",
    label_translation_key:
      "appointment.capture_test_result.result_options.positive"
  },
  {
    value: "negative",
    label_translation_key:
      "appointment.capture_test_result.result_options.negative"
  }
];

const ProviderAppointmentCaptureTestResult = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { t } = useTranslation();
  const { validate } = useYupValidation();

  const [photo, setPhoto] = useState();
  const [resultVerified, setResultVerified] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;
        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.capture_test_result.error_api"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.capture_test_result.title"));
  });

  /**
   * Set previously taken photo
   */
  useEffect(() => {
    if (appointment.test_result_photo) {
      setPhoto(appointment.test_result_photo);
    }
  }, [appointment]);

  /**
   * Handle take photo
   * @param {string} dataUri
   */
  const handleTakePhoto = dataUri => {
    if (dataUri) {
      setPhoto(dataUri);
    }
  };

  /**
   * Handle re-take photo
   */
  const handleRetakePhoto = () => {
    setPhoto();
  };

  /**
   * Handle next button
   */
  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    if (resultVerified) {
      postProviderAppointmentUpdate({
        variables: {
          body: {
            appointment_id: appointment.appointment_id,
            action: appointment.next_action,
            test_result_photo: photo || "",
            test_result: data.test_result,
            auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
            operator_location_id: sessionStorage.getItem(
              PROVIDER_SESSION_LOCATION
            ),
            service_code: appointment.service_selection_codes[0] || ""
          }
        }
      });
    } else {
      setShowConfirmationModal(true);
    }
  };

  const translateResult = result => {
    switch (result.toLowerCase()) {
      case "positive":
        return t("appointment.capture_test_result.result_options.positive");
      case "negative":
        return t("appointment.capture_test_result.result_options.negative");
      default:
        return "unknown";
    }
  };

  return (
    <Box data-cy="provider-patient-capture-test-result">
      {photo ? (
        <Box my={1} textAlign="center">
          <Button
            data-cy="button-retake-photo"
            onClick={e => handleRetakePhoto()}
          >
            {t("appointment.capture_test_result.button")}
          </Button>
        </Box>
      ) : null}
      <Box mb={2}>
        {hasGetUserMedia() ? (
          <>
            <Typography paragraph>
              {t("appointment.capture_test_result.photo_label")}
            </Typography>
            <Camera photo={photo} onTakePhoto={handleTakePhoto} />
          </>
        ) : (
          <Typography color="error">
            {t("appointment.capture_test_result.error_camera_access")}
          </Typography>
        )}
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        decorators={[focusOnErrors]}
        render={({ handleSubmit, submitErrors: errors = {}, values }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column">
                <Grid item xs>
                  <Field
                    name="test_result"
                    type="radio"
                    render={({ input }) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {t(
                            "appointment.capture_test_result.form.test_result"
                          )}
                        </FormLabel>
                        <RadioGroup aria-label="test result" {...input}>
                          <Box pt={1}>
                            <Grid container spacing={2}>
                              {TEST_RESULT_OPTIONS.map((result, index) => (
                                <Grid item xs={6} key={`result-${index}`}>
                                  <FormControlLabel
                                    data-cy={`input-${result.value}`}
                                    value={result.value}
                                    control={
                                      <Radio
                                        disabled={resultVerified}
                                        color="primary"
                                        checked={
                                          values.test_result === result.value
                                        }
                                      />
                                    }
                                    label={t(result.label_translation_key)}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </RadioGroup>
                        {!!errors.test_result && (
                          <FormHelperText error={true}>
                            {errors.test_result}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <FormActionDrawer
                setDrawerClearance={setDrawerClearance}
                nextButtonLabel={t(
                  "appointment.capture_test_result.button_next"
                )}
                loading={loading}
                disabled={!photo}
              />
            </form>
            {resultVerified && (
              <Box mt={2}>
                <Alert data-cy="alert-text" severity="success">
                  <Trans i18nKey="appointment.capture_test_result.alert_text">
                    Result verified as{" "}
                    <strong>
                      {{
                        result: translateResult(
                          values.test_result
                        ).toUpperCase()
                      }}
                    </strong>
                    . Click 'Next' to continue.
                  </Trans>
                </Alert>
              </Box>
            )}
            {photo && values.test_result && showConfirmationModal && (
              <Dialog
                open={true}
                onClose={() => setShowConfirmationModal(false)}
                data-cy="confirmation-modal"
              >
                <DialogTitle>
                  {t("appointment.capture_test_result.dialog.title")}
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    <Trans i18nKey="appointment.capture_test_result.dialog.text">
                      Please confirm that the result is{" "}
                      <strong>
                        {{
                          result: translateResult(
                            values.test_result
                          ).toUpperCase()
                        }}
                      </strong>
                    </Trans>
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    data-cy="button-no-verify"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    {t("appointment.capture_test_result.dialog.button_no")}
                  </Button>
                  <Button
                    data-cy="button-verify"
                    color="primary"
                    onClick={() => {
                      setResultVerified(true);
                      setShowConfirmationModal(false);
                    }}
                  >
                    {t("appointment.capture_test_result.dialog.button_yes")}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </>
        )}
      />
    </Box>
  );
};

export { ProviderAppointmentCaptureTestResult };
