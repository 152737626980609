import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paper, Box } from "@material-ui/core";
import { Sheet, SheetContent, Loader } from "../../components";
import {
  ProviderAppointmentCheckIn,
  ProviderAppointmentVerifyInsurance,
  ProviderAppointmentStart,
  ProviderAppointmentTestEnd,
  ProviderAppointmentComplete,
  ProviderAppointmentScanVial,
  ProviderAppointmentNotes,
  ProviderAppointmentVaxEnd,
  ProviderAppointmentCaptureTestResult
} from "./children";
import { isMexicoAppointment, isVaxAppointment } from "../../utils";

const ProviderAppointmentProcess = () => {
  const history = useHistory();
  const location = useLocation();

  const [drawerClearance, setDrawerClearance] = useState(0);
  const [appointment, setAppointment] = useState();

  useEffect(() => {
    const { state } = location;

    if (!state || !state.appointment) return history.push(`/provider`);

    setAppointment(state.appointment);
  }, [location]);

  if (!appointment) return <Loader />;

  return (
    <Sheet
      data-cy="provider-appointment-process"
      style={{ paddingBottom: `${drawerClearance}px` }}
    >
      <SheetContent>
        <Box mb={2}>
          <Paper>
            <Box p={2}>
              {appointment.next_action ? (
                <>
                  {appointment.next_action === "check_in" && (
                    <ProviderAppointmentCheckIn
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {appointment.next_action === "verify_insurance" && (
                    <ProviderAppointmentVerifyInsurance
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {(appointment.next_action === "start_test" ||
                    appointment.next_action === "start_vax") && (
                    <ProviderAppointmentStart
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {(appointment.next_action === "scan_vial" ||
                    appointment.next_action === "scan_vial_vax") && (
                    <ProviderAppointmentScanVial
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {appointment.next_action === "end_test" && (
                    <ProviderAppointmentTestEnd
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {appointment.next_action === "end_vax" && (
                    <ProviderAppointmentVaxEnd
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {appointment.next_action === "notes" && (
                    <ProviderAppointmentNotes
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                  {appointment.next_action === "antigen_results" && (
                    <ProviderAppointmentCaptureTestResult
                      appointment={appointment}
                      setAppointment={setAppointment}
                      setDrawerClearance={setDrawerClearance}
                    />
                  )}
                </>
              ) : (
                <ProviderAppointmentComplete
                  appointment={appointment}
                  setAppointment={setAppointment}
                  isVaxAppointment={isVaxAppointment(
                    appointment.service_selection_codes
                  )}
                  isMexicoAppointment={isMexicoAppointment(
                    appointment.service_selection_codes
                  )}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </SheetContent>
    </Sheet>
  );
};

export { ProviderAppointmentProcess };
