import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
  Divider,
  Link
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { useSignup } from "../../../contexts";
import { ButtonLoader, MaskedInputPhone } from "../../../components";
import { useYupValidation } from "../../../hooks";
import { POST_NEW_ORGANIZATION_REQUEST } from "../../../queries";
import { object, string } from "yup";
import { useMutation } from "@apollo/react-hooks";
import { Trans, useTranslation } from "react-i18next";

const validationSchema = object({
  given_name: string()
    .required()
    .label("First Name")
    .min(2),
  family_name: string()
    .required()
    .label("Last Name")
    .min(2),
  formatted_address: string()
    .required()
    .label("Business Address"),
  phone: string()
    .required()
    .label("Phone")
    .length(10),
  email: string()
    .required()
    .label("Email")
    .email()
});

const useClasses = makeStyles(theme => ({
  subFieldset: {
    paddingLeft: theme.spacing(4)
  }
}));

const SignUpInfo = () => {
  const { validate } = useYupValidation();
  const history = useHistory();
  const { t } = useTranslation();
  const { signupData } = useSignup();
  const theme = useTheme();
  const breakpointLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [signUpError, setSignUpError] = useState(false);
  const { public_name, org_name } = signupData;

  useEffect(() => {
    if (!public_name || !org_name) return history.push("/signup");
  }, [org_name, public_name]);

  const [postNewOrganizationRequest, { loading }] = useMutation(
    POST_NEW_ORGANIZATION_REQUEST,
    {
      onCompleted: ({ organizationRequest }) => {
        if (
          organizationRequest &&
          organizationRequest.status &&
          organizationRequest.status === "success"
        ) {
          history.push({ pathname: `/signup/finish` });
        } else {
          setSignUpError(true);
        }
      },
      onError: () => {
        setSignUpError(true);
      }
    }
  );

  const handleCompleteSignup = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    postNewOrganizationRequest({
      variables: {
        body: {
          ...signupData,
          ...data,
          name: `${data.given_name} ${data.family_name}`,
          nickname: data.given_name,
          accept_terms: true
        }
      }
    });
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        direction="column"
        style={{ height: "100%" }}
      >
        <Box p={4}>
          <Typography variant="h5" component="h1">
            <strong>{t("sign_up.info.title")}</strong>
          </Typography>
          <Typography paragraph>{t("sign_up.info.secondary_text")}</Typography>
          <Form
            onSubmit={handleCompleteSignup}
            initialValues={signupData}
            render={({ handleSubmit, submitErrors: errors = {}, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={breakpointLg ? 2 : 0}>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name="given_name"
                          render={({ input }) => (
                            <TextField
                              required
                              data-cy="input-given_name"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label={t("sign_up.info.form.first_name")}
                              {...input}
                              error={!!errors.given_name}
                              helperText={errors.given_name}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name="family_name"
                          render={({ input }) => (
                            <TextField
                              required
                              data-cy="input-family_name"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label={t("sign_up.info.form.last_name")}
                              {...input}
                              error={!!errors.family_name}
                              helperText={errors.family_name}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={breakpointLg ? 2 : 0}>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name="email"
                          render={({ input }) => (
                            <TextField
                              required
                              data-cy="input-email"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label={t("sign_up.info.form.email")}
                              {...input}
                              error={!!errors.email}
                              helperText={errors.email}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name="phone"
                          render={({ input }) => (
                            <TextField
                              required
                              data-cy="input-phone"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label={t("sign_up.info.form.phone")}
                              {...input}
                              error={!!errors.phone}
                              helperText={errors.phone}
                              InputProps={{ inputComponent: MaskedInputPhone }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="formatted_address"
                      render={({ input }) => (
                        <TextField
                          required
                          data-cy="input-formatted-address"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label={t("sign_up.info.form.business_address")}
                          {...input}
                          error={!!errors.formatted_address}
                          helperText={errors.formatted_address}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Divider />
                </Box>
                <Box mt={2}>
                  <Typography paragraph>
                    <Trans i18nKey="sign_up.info.terms">
                      By clicking "Complete" you are agreeing to the GoGetVax{" "}
                      <Link
                        href="https://www.gogetvax.com/policy#terms"
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="https://www.gogetvax.com/policy#policy"
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Trans>
                  </Typography>
                  {signUpError && (
                    <Typography color="error" paragraph>
                      {t("sign_up.info.error")}
                    </Typography>
                  )}
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                      <ButtonLoader
                        variant="contained"
                        onClick={() => history.push("/signup")}
                        loading={loading}
                      >
                        {t("sign_up.info.button_previous")}
                      </ButtonLoader>
                    </Grid>
                    <Grid item>
                      <ButtonLoader
                        data-cy="button-next"
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={loading}
                      >
                        {t("sign_up.info.button_complete")}
                      </ButtonLoader>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          />
        </Box>
      </Grid>
    </>
  );
};

export { SignUpInfo };
