const RESOLUTION_CODE_POS_STABLE = "pos_stable";
const RESOLUTION_CODE_POS_UNSTABLE = "pos_unstable";
const RESOLUTION_CODE_NEG_WITH_PMH = "neg_with_pmh";
const RESOLUTION_CODE_NEG_WITHOUT_PMH = "neg_without_pmh";

const RESOLUTION_CODE_COVID_19_TEST = [
  {
    label_translation_key: "components.resolution_code.options.positive_stable",
    value: RESOLUTION_CODE_POS_STABLE
  },
  {
    label_translation_key:
      "components.resolution_code.options.positive_unstable",
    value: RESOLUTION_CODE_POS_UNSTABLE
  },
  {
    label_translation_key: "components.resolution_code.options.neg_with_pmh",
    value: RESOLUTION_CODE_NEG_WITH_PMH
  },
  {
    label_translation_key: "components.resolution_code.options.neg_without_pmh",
    value: RESOLUTION_CODE_NEG_WITHOUT_PMH
  }
];

export {
  RESOLUTION_CODE_POS_STABLE,
  RESOLUTION_CODE_POS_UNSTABLE,
  RESOLUTION_CODE_NEG_WITH_PMH,
  RESOLUTION_CODE_NEG_WITHOUT_PMH,
  RESOLUTION_CODE_COVID_19_TEST
};
