import React from "react";
import { Box, Typography, Chip, Grid, SvgIcon } from "@material-ui/core";
import {
  BookmarkBorderOutlined,
  LocationOn,
  TodayOutlined
} from "@material-ui/icons";
import moment from "moment";
import { ReactComponent as Covid19TestIcon } from "../../images/service-covid-19-test.svg";
import { StatusChip } from "../Common";
import { SERVICE_CODE } from "../../constants";

const DEFAULT_DATE_FORMAT = "M/D/YY h:mm:ss A";

const PatientAppointment = ({ patient }) => {
  return (
    <Box>
      <Typography paragraph component="div">
        <StatusChip
          status={patient.appointment_status}
          size="small"
          variant="outlined"
        />
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <BookmarkBorderOutlined fontSize="small" />
        </Grid>
        <Grid item xs>
          <Typography>
            #{patient.appointment_id}
            {SERVICE_CODE[patient.service_code] &&
              ` - ${SERVICE_CODE[patient.service_code].label}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <TodayOutlined fontSize="small" />
        </Grid>
        <Grid item xs>
          <Typography>
            {moment(patient.scheduled_dt).format(DEFAULT_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <LocationOn fontSize="small" />
        </Grid>
        <Grid item xs>
          <Typography>
            {patient.account} - {patient.loc_city}
          </Typography>
        </Grid>
      </Grid>
      {patient.vial_id && (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <SvgIcon fontSize="small">
              <Covid19TestIcon />
            </SvgIcon>
          </Grid>
          <Grid item xs>
            <Typography>{patient.vial_id}</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export { PatientAppointment };
