import gql from "graphql-tag";

const GET_LOCATIONS = gql`
  query getLocations {
    locations
      @rest(
        type: "getLocations"
        path: "/portal/site_admin/location/get_locations"
        endpoint: "v1"
      ) {
      status
      results
    }
  }
`;

export { GET_LOCATIONS };
