import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_LOCATIONS } from "../../queries";
import { useTranslation } from "react-i18next";

const LocationSelect = ({
  errors = {},
  input = {},
  filterResults = () => true
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_LOCATIONS);

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error">
        {t("components.location.select.error_api")}
      </Typography>
    );

  const { locations } = data;

  if (!Array.isArray(locations.results) || !locations.results.length)
    return (
      <Typography color="error">
        {t("components.location.select.error_empty")}
      </Typography>
    );

  return (
    <FormControl margin="normal" fullWidth variant="outlined">
      <InputLabel id="location-select">
        {t("components.location.select.form.input_label")}
      </InputLabel>
      <Select
        {...input}
        labelId="location-select"
        id="location-select"
        label={t("components.location.select.form.label")}
        fullWidth
        data-cy="location-select"
      >
        <MenuItem disabled value="">
          {t("components.location.select.form.disabled_item")}
        </MenuItem>
        {locations.results.filter(filterResults).map(location => (
          <MenuItem
            key={`location-${location.id}`}
            value={location.id}
            data-cy="location-select-option"
          >
            {location.name} ({location.site_code})
          </MenuItem>
        ))}
      </Select>
      {!!errors.location && (
        <FormHelperText error={true}>{errors.location}</FormHelperText>
      )}
    </FormControl>
  );
};

export { LocationSelect };
