import React from "react";
import { default as ReactCamera, FACING_MODES } from "react-html5-camera-photo";
import { Box, makeStyles } from "@material-ui/core";
import "react-html5-camera-photo/build/css/index.css";

const useClasses = makeStyles(theme => ({
  cameraContainer: {
    "& video": {
      maxWidth: "100%"
    },
    "& img": {
      maxWidth: "100%"
    },
    "& #container-circles": {
      zIndex: 200
    },
    "& #inner-circle": {
      backgroundColor: theme.palette.primary.main
    }
  },
  lastphoto: {
    maxWidth: "100%"
  },
  cameraMask: {
    position: "relative"
  },
  cameraMaskClip: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: "20%",
    zIndex: 100,
    backgroundColor: theme.palette.common.black
  },
  cameraMaskClipBottom: {
    top: "auto",
    bottom: 0
  },
  cameraViewfinder: {
    position: "absolute",
    top: "22%",
    bottom: "22%",
    height: "56%",
    left: "2%",
    right: "2%",
    zIndex: 100,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`
  }
}));

const CameraMask = ({ children }) => {
  const classes = useClasses();

  return (
    <Box className={classes.cameraMask}>
      <Box className={classes.cameraMaskClip}></Box>
      <Box
        className={`${classes.cameraMaskClip} ${classes.cameraMaskClipBottom}`}
      ></Box>
      <Box className={classes.cameraViewfinder}></Box>
      {children}
    </Box>
  );
};

const Camera = ({ photo, onTakePhoto }) => {
  const classes = useClasses();

  return (
    <Box className={classes.cameraContainer}>
      <CameraMask>
        {photo ? (
          <Box>
            <img className={classes.lastphoto} src={photo} />
          </Box>
        ) : (
          <ReactCamera
            className={classes.camera}
            onTakePhotoAnimationDone={dataUri => onTakePhoto(dataUri)}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
          />
        )}
      </CameraMask>
    </Box>
  );
};

export { Camera };
