import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import moment from "moment";
import {
  PatientContact,
  PatientAppointment,
  PatientConsultation
} from "../../components/Patient";
import { useTranslation } from "react-i18next";

const useClasses = makeStyles(theme => ({
  textSubdued: {
    fontSize: "12px",
    color: theme.palette.grey["500"]
  }
}));

const menuItems = [
  {
    label_translation_key: "components.admin.patient_card.menu_items.contact",
    id: "contact"
  },
  {
    label_translation_key:
      "components.admin.patient_card.menu_items.appointment",
    id: "appointment"
  },
  {
    label_translation_key: "components.admin.patient_card.menu_items.notes",
    id: "notes"
  }
];

const PatientMenu = ({ onItemClick, items, activeItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (e, item) => {
    setAnchorEl(null);
    onItemClick(item.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id={`patient-card-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(item => (
          <MenuItem
            key={`menu-item-${item.id}`}
            onClick={e => handleItemClick(e, item)}
            selected={activeItem === item.id}
          >
            {t(item.label_translation_key)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const AdminPatientCard = ({ patient }) => {
  const classes = useClasses();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("contact");

  return (
    <Paper>
      <Box p={2}>
        <Grid container>
          <Grid item xs>
            <Typography component="h2" variant="h6">
              <strong>
                {patient.last_name}, {patient.first_name}
              </strong>
            </Typography>
            {patient.register_dt && (
              <Typography className={classes.textSubdued}>
                {t("components.admin.patient_card.registered_date", {
                  registerDate: moment(patient.register_dt).format("MM-DD-YYYY")
                })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <PatientMenu
              items={menuItems}
              onItemClick={tabId => setActiveTab(tabId)}
              activeItem={activeTab}
            />
          </Grid>
        </Grid>
        <Box pt={1} mb={2}>
          <Divider />
        </Box>
        {activeTab === "contact" && <PatientContact patient={patient} />}
        {activeTab === "appointment" && (
          <PatientAppointment patient={patient} />
        )}
        {activeTab === "notes" && (
          <PatientConsultation
            appointmentId={patient.appointment_id}
            pastConsultations={patient.consultations}
          />
        )}
      </Box>
    </Paper>
  );
};

export { AdminPatientCard };
