const esTranslations = {
  translation: {
    provider: {
      provider: {
        title: "Localización",
        intro_text: "Seleccione su ubicación",
        button: "Próximo"
      }
    },
    show_404: {
      title: "Página no encontrada"
    },
    appointment: {
      lookup: {
        title: "Confirmación de escaneo",
        button: "Cambiar locación",
        secondary_text: "O ingrese el ID de la cita",
        field_label: "ID de cita",
        button_secondary: "Enviar",
        error_look_up: "No se pudo encontrar la cita"
      },
      complete: {
        title: "Cita completa",
        intro_text: "La cita ya está completa.",
        button_check_in: "Check-in Paciente siguiente",
        button_reprint_label: "Reimprimir etiqueta",
        button_rescan_vial: "Vuelva a escanear el vial",
        button_sign_out: "Desconectar",
        error_reprint_label: "No se puede volver a imprimir la etiqueta"
      },
      check_in: {
        title: "Confirmar paciente",
        intro_text: "Verificar la identidad y la ubicación del paciente",
        error_dob:
          "La fecha de nacimiento no coincide con el registro de la cita",
        check_in_error: "Incapaz de registrar al paciente"
      },
      notes: {
        title: "Notas del paciente",
        form: {
          injection_site: "Lugar de inyección intramuscular"
        },
        button: "Terminar la vacuna",
        error: "Incapaz de registrar al paciente",
        injection_site_options: {
          left_arm: "Deltoides izquierdo (brazo)",
          right_arm: "Deltoides derecho (brazo)",
          left_thigh: "Vasto lateral izquierdo (muslo)",
          right_thigh: "Vastus lateralis derecho (muslo)"
        }
      },
      scan_vial: {
        title: "Vial de escaneo",
        error_update:
          "No se puede actualizar la cita con la identificación del vial",
        error_vial_duplicate: "Este es un escaneo de ID de vial duplicado.",
        error_vial_id: "Ya se ha asignado una ID de vial a esta muestra.",
        error_api: "No se puede establecer la identificación del vial",
        error_barcode: "Entrada de código de barras no válida.",
        secondary_text: "Vial escaneado con éxito",
        vial_text: "Detalles del vial",
        manual_text: "O ingrese manualmente",
        button_link: {
          re_scan_vial: "Volver a escanear",
          vial_details: "Detalles"
        },
        form: {
          pharma_label: "Pharma",
          workstation: "Puesto de trabajo",
          choose_label: "Escoger...",
          lot_no: {
            label: "Lote #"
          },
          expiry: {
            label: "Fecha de caducidad"
          },
          vial_id: {
            label: "ID de vial"
          }
        },
        button: "Agregar",
        button_next: {
          start_vaccine: "Iniciar la vacuna",
          accept_scan: "Aceptar escaneo"
        }
      },
      start: {
        title: "Paciente registrado",
        intro_text: "{{patientName}} se registró con éxito",
        api_error: "Incapaz de registrar al paciente"
      },
      test_end: {
        title: "Examen en progreso",
        intro_text: "Prueba para {{patientName}} está en proceso.",
        button: "Finalizar prueba",
        api_error: "No se pudo finalizar la prueba"
      },
      vax_end: {
        title: "Recordatorio para el paciente",
        intro_text: "Recuerde a Paitent que recuerde lo siguiente:",
        reminder_1:
          "Permanezca en la zona de observación durante 10-15 minutos para que podamos asegurarnos de que no tenga reacciones adversas inmediatas.",
        reminder_2:
          "Si se siente mal, notifique a un miembro del personal de inmediato.",
        reminder_3:
          "Recuerde que esta vacuna requiere un refuerzo. Recibirá un recordatorio de su segunda cita (que ya había reservado cuando hizo esta cita).",
        reminder_4:
          "Si en algún momento se siente enfermo después de salir de aquí hoy, busque atención médica inmediata llamando al 911.",
        button: "Visita final",
        error_api: "No se pudo finalizar la vacunación"
      },
      verify_insurance: {
        title: "Verificar seguro",
        button: "Volver a tomar la foto",
        error_api: "No se puede verificar el seguro"
      },
      capture_test_result: {
        photo_label: "Por favor tome una foto del resultado",
        result_options: {
          positive: "Positivo",
          negative: "Negativo"
        },
        error_api: "Error de API",
        title: "Capturar el resultado de la prueba",
        button: "Volver a tomar la foto",
        error_camera_access:
          "Debe habilitar el acceso a la cámara en su dispositivo",
        form: {
          test_result: "Resultado de la prueba"
        },
        button_next: "Próximo",
        dialog: {
          title: "Confirmar resultado",
          text: "Confirme que el resultado es <2>{{result}}</2>",
          button_no: "No, cancelar",
          button_yes: "Si correcto"
        },
        alert_text:
          "Resultado verificado como <2>{{result}}</2>. Presione siguiente para continuar."
      }
    },
    auth: {
      login_form: {
        intro_text: "Ingrese el token de su proveedor",
        button: "Registrarse"
      },
      logout: {
        logout_text: "Ahora estás registrado.",
        button: "Volver a iniciar sesión"
      }
    },
    label: {
      label_scanner: {
        title: "Escáner de etiquetas",
        secondary_text: "O ingrese manualmente",
        form: {
          appointment_id: "ID de cita",
          button: "Enviar"
        },
        success_text: "Escaneado con éxito",
        error_api: "Error al registrar el escaneo",
        success_api: "Escaneado con éxito"
      }
    },
    patient: {
      patient_search: {
        title: "Búsqueda de pacientes",
        form: {
          first_name: "Nombre de pila",
          last_name: "Apellido",
          dob: "Fecha de nacimiento",
          phone_number: "Número de teléfono",
          email: "Correo electrónico"
        },
        button_clear: "Claro",
        button_search: "Buscar",
        table: {
          apt_id: "Apto. ID",
          name: "Nombre",
          dob: "DOB",
          phone: "Teléfono",
          email: "Correo electrónico",
          service: "Servicio",
          apt_status: "Apto. Estado"
        }
      },
      vial_search: {
        title: "Búsqueda de viales",
        secondary_text: "O ingrese Vial ID",
        form: {
          vial_id: "ID de vial"
        },
        button_clear: "Claro",
        button_search: "Buscar",
        error: "No se han encontrado resultados",
        table: {
          apt_id: "Apto. ID",
          patient_id: "ID del paciente",
          name: "Nombre",
          dob: "DOB",
          phone: "Teléfono",
          email: "Correo electrónico",
          vial_id: "ID de vial",
          apt_status: "Apto. Estado"
        }
      }
    },
    sign_up: {
      sign_up: {
        quote:
          "La plataforma de GoGetVax nos facilitó el registro y la administración de vacunas rápidamente. Al equipo le encanta porque es fácil de usar. A los pacientes les encanta porque es perfecto. Todas las organizaciones deben utilizar GoGetVax para las vacunas."
      },
      finish: {
        intro_text: "¡Felicidades! Su cuenta ya está creada",
        secondary_text:
          "Un miembro de nuestro equipo de incorporación de proveedores se comunicará con usted para ayudarlo a prepararse para comenzar a recibir citas."
      },
      info: {
        title: "Información de la cuenta",
        secondary_text:
          "Esto nos ayudará a generar rápidamente su listado en el mapa GoGetTested.",
        form: {
          first_name: "Nombre de pila",
          last_name: "Apellido",
          email: "Correo electrónico",
          phone: "Teléfono",
          business_address: "Dirección de Negocios"
        },
        error: "Lo sentimos, hubo un error al enviar su solicitud.",
        button_previous: "Previo",
        button_complete: "Completo",
        terms:
          'Al hacer clic en "Completar", acepta los <2>Términos y condiciones</2> y la <6>Política de privacidad</6> de GoGetVax.'
      },
      start: {
        title: "Crea tu cuenta GoGetTested",
        secondary_text:
          "Ingrese su información a continuación y estará en camino de acceder a la forma más fácil de administrar las pruebas y las vacunas.",
        form: {
          legal_name: "Nombre del proveedor legal"
        },
        button: "Próximo",
        sign_in: "¿Ya tienes una cuenta? <1>Registrarse</1>"
      }
    },
    common: {
      error: "Lo siento, ocurrió un error",
      page_not_found: "Lo sentimos, no pudimos encontrar esa página",
      go_home: "Ir al panel de control"
    },
    components: {
      chat_teaser: {
        title: "Quieres hablar con un humano,",
        button_label: "Nosotros estamos aqui"
      },
      patient_details: {
        dob: "Fecha de nacimiento ({{patientDob}})"
      },
      appointment_status: {
        options: {
          pending: "Pendiente",
          cancelled: "Cancelado",
          scheduled: "Programado",
          checked_in: "Registrado",
          consultation_in_progress: "En progreso",
          test_in_progress: "En progreso",
          vial_scanned: "Vial escaneado",
          test_completed: "Terminado",
          record_locked: "Bloqueado",
          vax_vial_scanned: "Vial escaneado",
          notes: "Notas",
          verify_insurance: "Verificar seguro",
          start_vax: "Iniciar Vax",
          end_vax: "End Vax"
        }
      },
      barcodes: {
        definitions: {
          gtin: "GTIN",
          batch_lot_no: "Lote / Número de lote",
          production_date: "Fecha de producción",
          best_before_date: "Consumir preferentemente antes del",
          expiration_date: "Fecha de caducidad",
          serial_no: "Número de serie"
        }
      },
      resolution_code: {
        options: {
          positive_stable: "Estable positivo",
          positive_unstable: "Positivo inestable",
          neg_with_pmh: "Negativo con antecedentes médicos primarios",
          neg_without_pmh: "Negativo sin antecedentes médicos primarios"
        }
      },
      location: {
        select: {
          error_api: "No se pudieron cargar las ubicaciones",
          form: {
            input_label: "Localización",
            label: "localización",
            disabled_item: "Escoger..."
          },
          error_empty: "No hay ubicaciones disponibles."
        }
      },
      admin: {
        header: {
          menu: {
            patient_check_in: "Registro del paciente",
            patient_search: "Búsqueda de pacientes",
            vial_search: "Búsqueda de viales",
            label_scanner: "Escáner de etiquetas",
            logout: "Cerrar sesión"
          }
        },
        patient_card: {
          registered_date: "Registrado en {{registerDate}}",
          menu_items: {
            contact: "Contacto",
            appointment: "Cita",
            notes: "Notas"
          }
        }
      },
      auth: {
        patient_auth_by_dob: {
          form: {
            verify_dob: "Verificar fecha de nacimiento"
          }
        },
        auth_0_login_button: {
          button: "Iniciar sesión"
        },
        no_permission: {
          title: "No autorizado",
          intro_text: "Lo siento, no tienes permiso para hacer esto."
        }
      },
      patient: {
        consultation: {
          error: {
            review_in_progress:
              "Ya se está realizando una revisión para este paciente",
            api: "Lo sentimos, se produjo un error de API. Inténtalo de nuevo."
          },
          button: "Agregar notas",
          form: {
            notes: "Notas",
            resolution_code: {
              input_label: "Código de resolución",
              select_label: "Código de resolución"
            },
            disabled_menu_item: "Escoger...",
            button_finish: "Finalizar y guardar"
          },
          no_notes_text: "No se encontraron notas anteriores."
        },
        contact: {
          email_status: "Verificado",
          phone_status: "Verificado"
        }
      }
    }
  }
};

export { esTranslations };
