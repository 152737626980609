import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { IntroText } from "./Introtext";
import { useTranslation } from "react-i18next";

const useClasses = makeStyles({
  spanBlock: {
    display: "block"
  }
});

const PatientDetails = ({ name, dob }) => {
  const classes = useClasses();
  const { t } = useTranslation();

  return (
    <IntroText>
      {name && (
        <Box
          component="span"
          className={classes.spanBlock}
          data-cy="patient-details-name"
        >
          <strong>{name}</strong>
        </Box>
      )}
      {dob && (
        <Box
          component="span"
          className={classes.spanBlock}
          data-cy="patient-details-dob"
        >
          <strong>
            {t("components.patient_details.dob", {
              patientDob: dob
            })}
          </strong>
        </Box>
      )}
    </IntroText>
  );
};

export { PatientDetails };
