import gql from "graphql-tag";

const POST_PROVIDER_LOGIN = gql`
  mutation postProviderLogin($body: Object) {
    provider(body: $body)
      @rest(
        type: "postProviderLogin"
        path: "/provider/login"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      auth_token
    }
  }
`;

export { POST_PROVIDER_LOGIN };
