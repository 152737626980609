import bark from "bark-js";
import moment from "moment";

const parseBarcodeDataMatrix = (barcode, errorFn = () => {}) => {
  let parts = {};

  try {
    const parsed = bark(barcode);

    if (Array.isArray(parsed.elements) && parsed.elements.length > 0) {
      parsed.elements.forEach(element => {
        parts = { ...parts, [element.ai]: element.value };
      });
    }
  } catch (err) {
    errorFn();
    console.error(err);
  }

  return parts;
};

/**
 * Takes object of data and converts it into a GS1 barcode string
 * See https://www.gs1.org/docs/barcodes/GS1_DataMatrix_Guideline.pdf
 *
 * @param {*} data
 * @param {string} fromDateFormat
 * @param {string} keyIdentifier
 */
const parseBarcodeObjectToGS1String = (
  data,
  fromDateFormat = "MMDDYYYY",
  keyIdentifier = "ai_"
) => {
  return Object.keys(data)
    .sort()
    .map(ai => {
      const applicationIdentifier = ai.replace(keyIdentifier, "");
      let value = data[ai];

      let gs = "";

      if (
        applicationIdentifier === "11" ||
        applicationIdentifier === "15" ||
        applicationIdentifier === "17"
      ) {
        gs = String.fromCharCode(29);
        value = moment(value, fromDateFormat).format("YYMMDD");
      }

      return `${gs}${applicationIdentifier}${value}`;
    })
    .join("");
};

export { parseBarcodeDataMatrix, parseBarcodeObjectToGS1String };
