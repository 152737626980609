import React from "react";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { RestLink } from "apollo-link-rest";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import ErrorMap from "./ErrorTranslations/ErrorMap";
import { PROVIDER_SESSION_AUTH } from "./constants";
import { ApolloProvider } from "@apollo/react-hooks";

const AuthorizedApolloProvider = ({ children }) => {
  const GLOBAL_CACHE = new InMemoryCache();

  /**
   * Error function
   *
   * @param {*} err
   */
  const ErrorFunc = err => {
    console.log(err);
  };

  /**
   * Setup REST link
   */
  const restLink = new RestLink({
    uri: `${process.env.REACT_APP_API_HOST}/api`,
    endpoints: {
      v1: `${process.env.REACT_APP_API_HOST}/api`,
      v2: `${process.env.REACT_APP_API_HOST}/api/v2`,
      chat: `${process.env.REACT_APP_AMZ_CONNECT_API_GATEWAY}`
    }
  });

  /**
   * Handle Errors
   */
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      const { operationName } = operation;
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) {
        if (networkError.statusCode === 401) {
          // sessionStorage.removeItem(PROVIDER_SESSION_AUTH);
          // window.location.replace("/");
        }

        const ERROR_MESSAGE = networkError.result;

        if (ERROR_MESSAGE) {
          if (ErrorMap[ERROR_MESSAGE]) ErrorFunc(ErrorMap[ERROR_MESSAGE]);
          else ErrorFunc(ERROR_MESSAGE);
        }
      }
    }
  );

  /**
   * Get auth0 token for provider routes
   */
  const withTokenLink = setContext(() => ({
    auth0Token: sessionStorage.getItem(PROVIDER_SESSION_AUTH) || ""
  }));

  /**
   * Add auth headers
   */
  const authLink = setContext((_, { headers, auth0Token }) => {
    return {
      headers: {
        ...headers,
        ...(auth0Token ? { authorization: `Bearer ${auth0Token}` } : {})
      }
    };
  });

  /**
   * Create client
   */
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, withTokenLink, authLink, restLink]),
    cache: GLOBAL_CACHE
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { AuthorizedApolloProvider };
