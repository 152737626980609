import React, { useEffect, useState } from "react";
import { LANGUAGES } from "../constants";
import i18n from "../translations";
import * as queryString from "query-string";
import { useLocation } from "react-router-dom";
import { usePreferences } from "../hooks";

const LanguageContext = React.createContext();

const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const { language: languagePreference, updatePreferences } = usePreferences();

  const [language, setLanguage] = useState(languagePreference);

  useEffect(() => {
    i18n.changeLanguage(language);
    updatePreferences({ language });
  }, [language]);

  /**
   * Set language from search param e.g. ?lang=es
   */
  useEffect(() => {
    if (!location.search) return;

    const { lang = {} } = queryString.parse(location.search);

    if (lang) {
      const [language] = LANGUAGES.filter(language => language.key === lang);

      if (language) setLanguage(language.key);
    }
  }, [location]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <LanguageContext.Consumer>{children}</LanguageContext.Consumer>
    </LanguageContext.Provider>
  );
};

const useLanguages = () => React.useContext(LanguageContext);

export { LanguageProvider, useLanguages };
