import React from "react";
import { Chip } from "@material-ui/core";
import PropTypes from "prop-types";
import { theme } from "../../theme";
import { APPOINTMENT_STATUS } from "../../constants";
import { useTranslation } from "react-i18next";

const getStyles = (severity, variant) => {
  if (variant === "outlined") {
    switch (severity) {
      case "success":
        return {
          borderColor: theme.palette.success.dark,
          color: theme.palette.success.dark
        };
        break;
      case "warning":
        return {
          borderColor: theme.palette.warning.dark,
          color: theme.palette.warning.dark
        };
      case "error":
        return {
          borderColor: theme.palette.error.dark,
          color: theme.palette.error.dark
        };
        break;
      default:
        return {
          borderColor: theme.palette.info.dark,
          color: theme.palette.info.dark
        };
    }
  } else {
    switch (severity) {
      case "success":
        return {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.common.white
        };
      case "warning":
        return {
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.common.white
        };
      case "error":
        return {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.common.white
        };
        break;
      default:
        return {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.common.white
        };
    }
  }
};

const StatusChip = ({ status, variant = "default", ...rest }) => {
  const { t } = useTranslation();

  if (!APPOINTMENT_STATUS[status]) return null;

  return (
    <Chip
      label={
        APPOINTMENT_STATUS[status].label_translation_key
          ? t(APPOINTMENT_STATUS[status].label_translation_key)
          : "Unknown"
      }
      variant={variant}
      {...rest}
      style={getStyles(
        APPOINTMENT_STATUS[status].severity
          ? APPOINTMENT_STATUS[status].severity
          : "error",
        variant
      )}
    />
  );
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["default", "outlined"])
};

export { StatusChip };
