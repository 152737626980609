import React from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ProviderLoginWrapper } from "./ProviderLoginWrapper";
import { useTranslation } from "react-i18next";

const ProviderLogout = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ProviderLoginWrapper showLogo={true}>
      <Box p={2}>
        <Box mx={2}>
          <Paper>
            <Box p={2}>
              <Typography paragraph align="center">
                {t("auth.logout.logout_text")}
              </Typography>
              <Button
                onClick={() => history.push({ pathname: `/provider/login` })}
                fullWidth
                variant="contained"
                color="secondary"
              >
                {t("auth.logout.button")}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </ProviderLoginWrapper>
  );
};

export { ProviderLogout };
