import gql from "graphql-tag";

const POST_PROVIDER_LOOKUP_APPOINTMENT = gql`
  mutation postProviderLookupAppointment($body: Object) {
    appointment(body: $body)
      @rest(
        type: "postProviderLookupAppointment"
        path: "/provider/lookup_appointment"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      appointment_id
      patient_name
      patient_dob
      patient_address
      patient_gender
      next_action
      insurance_photo
      date
      location
      service_selection_codes
      service_selection
      status
    }
  }
`;

export { POST_PROVIDER_LOOKUP_APPOINTMENT };
