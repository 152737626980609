import {
  SERVICE_COVID_19_TEST_ANTIGEN,
  SERVICE_COVID_19_TEST_MEXICO,
  SERVICE_COVID_19_TEST_MEXICO_ANTIGEN
} from "../constants";

const MEXICO_SERVICE_CODES = [
  SERVICE_COVID_19_TEST_ANTIGEN,
  SERVICE_COVID_19_TEST_MEXICO,
  SERVICE_COVID_19_TEST_MEXICO_ANTIGEN
];

const isMexicoAppointment = appointmentCodes =>
  appointmentCodes && appointmentCodes.length > 0
    ? appointmentCodes.some(code => MEXICO_SERVICE_CODES.includes(code))
    : false;

export { isMexicoAppointment };
