import breakpoints from "./breakpoints";
import palette from "./palette";
import spacing from "./spacing";
import overrides from "./overrides";
import typography from "./typography";
import zIndex from "./zIndex";
import props from "./props";
import shape from "./shape";
import position from "./position";
import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  props,
  shape,
  breakpoints,
  palette,
  spacing,
  overrides,
  typography,
  zIndex,
  position
});

export { theme };
