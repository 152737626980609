import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import { useSignup } from "../../../contexts";
import { useTranslation } from "react-i18next";

const SignUpFinish = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { signupData } = useSignup();
  const { org_name } = signupData;

  useEffect(() => {
    if (!org_name) history.push("/signup");
  }, [org_name]);

  return (
    <>
      <Grid
        container
        justify="space-between"
        direction="column"
        style={{ height: "100%" }}
      >
        <Box p={4}>
          <Typography variant="h5" component="h1">
            <strong>{t("sign_up.finish.intro_text")}</strong>
          </Typography>
          <Box my={2}>
            <Typography>{t("sign_up.finish.secondary_text")}</Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export { SignUpFinish };
