const PROVIDER_ROLES = {
  BILLING_ADMIN: ["Billing Admin", "billing_admin"],
  CARE_PROVIDER: ["Care Provider", "care_provider"],
  CLINICAL_PROVIDER: ["Clinical Provider", "clinical_provider"],
  CUSTOMER_CONTACT: ["Customer Contact", "customer_contact"],
  ORG_ADMIN: ["Org Admin", "org_admin"],
  SITE_ADMIN: ["Site Admin", "site_admin"],
  SUPER_ADMIN: ["Super Admin", "super_admin"]
};

export { PROVIDER_ROLES };
