import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ScanSettings, Barcode, CameraAccess } from "scandit-sdk";
import ScanditBarcodeScanner from "scandit-sdk-react";

const BarcodeScanner = ({
  handleScan,
  handleError = console.error,
  processing = false,
  licenseKey = process.env.REACT_APP_SCANDIT_KEY
}) => {
  const [cameraAccess, setCameraAccess] = useState(false);

  const getScanSettings = () =>
    new ScanSettings({
      enabledSymbologies: [
        Barcode.Symbology.CODE128,
        Barcode.Symbology.INTERLEAVED_2_OF_5,
        Barcode.Symbology.IATA_2_OF_5,
        Barcode.Symbology.GS1_DATABAR,
        Barcode.Symbology.GS1_DATABAR_EXPANDED,
        Barcode.Symbology.GS1_DATABAR_LIMITED,
        Barcode.Symbology.DATA_MATRIX
      ]
    });

  /**
   * Make sure we have camera access
   */
  useEffect(() => {
    async function getCameraAccess() {
      const cameras = await CameraAccess.getCameras();

      if (Array.isArray(cameras)) {
        const availableCameras = cameras.filter(camera => camera.deviceId);

        if (availableCameras.length > 0) setCameraAccess(true);
      }
    }
    getCameraAccess();
  }, []);

  if (!cameraAccess) return null;

  return (
    <ScanditBarcodeScanner
      licenseKey={licenseKey}
      engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build"
      onReady={console.log}
      onScan={handleScan}
      onScanError={handleError}
      scanSettings={getScanSettings()}
      preloadEngine={true}
      paused={!!processing}
      accessCamera={!processing}
    />
  );
};

BarcodeScanner.propTypes = {
  handleScan: PropTypes.func.isRequired,
  handleError: PropTypes.func
};

export { BarcodeScanner };
