import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Box,
  Typography,
  TextField,
  Divider,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  FormActionDrawer,
  BarcodeScanner,
  ButtonLoader,
  ButtonLink,
  MaskedInputDate
} from "../../../components";
import { useAdminHeader } from "../../../contexts";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import {
  PROVIDER_SESSION_LOCATION,
  PROVIDER_SESSION_AUTH,
  BARCODES_GS1_AI_DEFINITIONS
} from "../../../constants";
import { Form, Field } from "react-final-form";
import { string, object } from "yup";
import createDecorator from "final-form-focus";
import {
  usePermissions,
  useYupValidation,
  useProviderAuth
} from "../../../hooks";
import { Barcode } from "scandit-sdk";
import {
  parseBarcodeDataMatrix,
  parseBarcodeObjectToGS1String
} from "../../../utils";
import * as isEmpty from "lodash.isempty";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const validationSchemaTested = object({
  vial_id: string()
    .required()
    .trim()
    .label("Vial Id")
});

const validationSchemaVax = object({
  ai_10: string()
    .required()
    .trim()
    .label("Lot #"),
  ai_01: string()
    .required()
    .trim()
    .label("GTIN"),
  ai_17: string()
    .required()
    .trim()
    .label("Expiry date")
});

const GTIN_OPTIONS = [
  {
    label: "Moderna",
    gtin: "00380777273990"
  },
  {
    label: "Pfizer",
    gtin: "00380777273991"
  }
];

/**
 * Takes object of GS1 elements and replaces GS1 key with human readable key
 *
 * @param {object} gs1Elements
 */
const generateReadableGS1Keys = gs1Elements => {
  let elements = {};

  if (!isEmpty(gs1Elements)) {
    Object.keys(gs1Elements).forEach(key => {
      const newKey =
        BARCODES_GS1_AI_DEFINITIONS[key] &&
        BARCODES_GS1_AI_DEFINITIONS[key].value
          ? BARCODES_GS1_AI_DEFINITIONS[key].value
          : `ai_${key}`;

      elements = { ...elements, [newKey]: gs1Elements[key] };
    });
  }

  return elements;
};

const ProviderAppointmentScanVial = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { validate } = useYupValidation();
  const [showVialDetails, setShowVialDetails] = useState(false);

  const [vialData, setVialData] = useState();

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const {
          next_action = "",
          status = "failed",
          reason_code = ""
        } = appointment;

        if (status === "success") {
          setAppointment(appointment => ({
            ...appointment,
            next_action
          }));
        } else {
          let errorMsg = t("appointment.scan_vial.error_update");

          if (reason_code === "dupe") {
            errorMsg = t("appointment.scan_vial.error_vial_duplicate");
          } else if (reason_code === "already_assigned") {
            errorMsg = t("appointment.scan_vial.error_vial_id");
          }

          enqueueSnackbar(errorMsg, {
            variant: "error"
          });
        }
      },
      onError: () => {
        enqueueSnackbar(t("appointment.scan_vial.error_api"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.scan_vial.title"));
  });

  /**
   * Handle scanned barcode
   *
   * @param {array} barcodes
   */
  const handleScan = ({ barcodes = [] }) => {
    const [barcode] = barcodes;

    if (!barcode.data) return;

    let vialData = { vial_id: barcode.data };

    if (barcode.symbology === Barcode.Symbology.DATA_MATRIX) {
      vialData = {
        ...vialData,
        elements: parseBarcodeDataMatrix(barcode.data)
      };
    }

    setVialData(vialData);
  };

  const generateGS1VialData = data => {
    const barcodeData = parseBarcodeObjectToGS1String(data);

    const elements = parseBarcodeDataMatrix(barcodeData, () => {
      enqueueSnackbar(t("appointment.scan_vial.error_barcode"), {
        variant: "error"
      });
    });

    return !isEmpty(elements) ? { vial_id: barcodeData, elements } : undefined;
  };

  const onSubmit = async data => {
    // Select appropriate validation schema based on appointment next_action
    const validationSchema =
      appointment.next_action === "scan_vial_vax"
        ? validationSchemaVax
        : validationSchemaTested;

    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    const vialData =
      appointment.next_action === "scan_vial_vax"
        ? generateGS1VialData(data)
        : { vial_id: data.vial_id };

    setVialData(vialData);
  };

  const handleNext = () => {
    let theVialData = vialData;

    if (!isEmpty(vialData.elements)) {
      const elementsWithReadableKeys = generateReadableGS1Keys(
        vialData.elements
      );

      theVialData = { ...vialData, elements: elementsWithReadableKeys };
    }

    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: appointment.next_action,
          vial_data: theVialData,
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  return (
    <>
      <Box data-cy="provider-patient-scan-vial">
        <BarcodeScanner
          handleScan={data => handleScan(data)}
          processing={loading || vialData}
        />
        {vialData && vialData.vial_id && (
          <Box my={2}>
            <Grid
              container
              spacing={1}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <CheckCircle color="primary" />
              </Grid>
              <Grid item>
                <Box textAlign="center">
                  <Typography>
                    {t("appointment.scan_vial.secondary_text")}
                  </Typography>
                  <Typography variant="body2">{vialData.vial_id}</Typography>
                  {vialData.elements && (
                    <>
                      <ButtonLink
                        label={t(
                          "appointment.scan_vial.button_link.vial_details"
                        )}
                        onClick={() => setShowVialDetails(true)}
                      />
                      <Dialog
                        open={showVialDetails}
                        onClose={() => setShowVialDetails(false)}
                        maxWidth="xs"
                        fullWidth={true}
                      >
                        <DialogTitle>
                          {t("appointment.scan_vial.vial_text")}
                        </DialogTitle>
                        <DialogContent>
                          <Box pb={2}>
                            {Object.keys(vialData.elements).map(key => (
                              <Typography
                                key={`vial-element-${key}`}
                                variant="body2"
                              >
                                <strong>
                                  {BARCODES_GS1_AI_DEFINITIONS[key] &&
                                  BARCODES_GS1_AI_DEFINITIONS[key]
                                    .label_translation_key
                                    ? t(
                                        BARCODES_GS1_AI_DEFINITIONS[key]
                                          .label_translation_key
                                      )
                                    : key}
                                  :
                                </strong>{" "}
                                {vialData.elements[key]}
                              </Typography>
                            ))}
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <ButtonLink
                  label={t("appointment.scan_vial.button_link.re_scan_vial")}
                  onClick={() => setVialData()}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {!vialData && (
          <>
            <Divider variant="middle" />
            <Box p={2}>
              <Typography align="center" gutterBottom>
                {t("appointment.scan_vial.manual_text")}
              </Typography>
              <Form
                onSubmit={onSubmit}
                initialValues={{}}
                decorators={[focusOnErrors]}
                render={({ handleSubmit, submitErrors: errors = {} }) => (
                  <form
                    data-cy="provider-appointment-scan"
                    onSubmit={handleSubmit}
                  >
                    {appointment.next_action === "scan_vial_vax" ? (
                      <>
                        <Field
                          name="ai_01"
                          render={({ input }) => (
                            <FormControl
                              margin="normal"
                              fullWidth
                              variant="outlined"
                            >
                              <InputLabel id="vial-gtin">
                                {t("appointment.scan_vial.form.pharma_label")}
                              </InputLabel>
                              <Select
                                {...input}
                                labelId="vial-gtin"
                                id="vial-gtin"
                                label={t(
                                  "appointment.scan_vial.form.workstation"
                                )}
                                fullWidth
                                error={!!errors.ai_01}
                                data-cy="vial-gtin-select"
                              >
                                <MenuItem disabled value="">
                                  {t("appointment.scan_vial.form.choose_label")}
                                </MenuItem>
                                {GTIN_OPTIONS.map((option, index) => (
                                  <MenuItem
                                    key={`option-${index}`}
                                    value={option.gtin}
                                    data-cy="vial-gtin-option"
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {!!errors.ai_01 && (
                                <FormHelperText>{errors.ai_01}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                        <Field
                          name="ai_10"
                          render={({ input }) => (
                            <TextField
                              {...input}
                              label={t(
                                "appointment.scan_vial.form.lot_no.label"
                              )}
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              error={!!errors.ai_10}
                              helperText={errors.ai_10}
                              inputProps={{
                                "data-lpignore": "true",
                                "data-cy": "input-ai_10"
                              }}
                            />
                          )}
                        />
                        <Field
                          name="ai_17"
                          render={({ input }) => (
                            <TextField
                              {...input}
                              label={t(
                                "appointment.scan_vial.form.expiry.label"
                              )}
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              error={!!errors.ai_17}
                              helperText={errors.ai_17}
                              InputProps={{
                                inputComponent: MaskedInputDate,
                                inputProps: {
                                  "data-lpignore": "true",
                                  "data-cy": "input-ai_17"
                                }
                              }}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <Field
                        name="vial_id"
                        render={({ input }) => (
                          <TextField
                            {...input}
                            label={t(
                              "appointment.scan_vial.form.vial_id.label"
                            )}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            error={!!errors.vial_id}
                            helperText={errors.vial_id}
                            inputProps={{
                              "data-lpignore": "true",
                              "data-cy": "input-vial_id"
                            }}
                          />
                        )}
                      />
                    )}
                    <ButtonLoader
                      data-cy="button-vial-add"
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={loading}
                      fullWidth
                    >
                      {t("appointment.scan_vial.button")}
                    </ButtonLoader>
                  </form>
                )}
              />
            </Box>
          </>
        )}
      </Box>
      {vialData && (
        <FormActionDrawer
          setDrawerClearance={setDrawerClearance}
          handleNext={e => handleNext()}
          nextButtonLabel={
            appointment.next_action === "scan_vial_vax"
              ? t("appointment.scan_vial.button_next.start_vaccine")
              : t("appointment.scan_vial.button_next.accept_scan")
          }
          loading={loading}
        />
      )}
    </>
  );
};

export { ProviderAppointmentScanVial };
