import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useAdminHeader } from "../../contexts";
import { useTranslation } from "react-i18next";

const AuthNoPermission = () => {
  const { setTitle } = useAdminHeader();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("components.auth.no_permission.title"));
  });

  return (
    <Box p={2}>
      <Typography color="error" paragraph>
        {t("components.auth.no_permission.intro_text")}
      </Typography>
    </Box>
  );
};

export { AuthNoPermission };
