const props = {
  MuiButton: {
    disableElevation: true
  },
  MuiPaper: {
    elevation: 0
  },
  MuiLink: {
    underline: "always"
  }
};

export default props;
