import gql from "graphql-tag";

const POST_PROVIDER_ADMIN_GENERAL_SEARCH = gql`
  mutation postProviderAdminGeneralSearch($body: Object) {
    search(body: $body)
      @rest(
        type: "postProviderAdminGeneralSearch"
        path: "/portal/site-admin/general_search"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      status
      results
    }
  }
`;

export { POST_PROVIDER_ADMIN_GENERAL_SEARCH };
