import React from "react";
import { Link, makeStyles } from "@material-ui/core";

const useClasses = makeStyles({
  mapImage: {
    borderRadius: "8px"
  }
});

const GoogleMapImage = ({
  address,
  zoom = 10,
  size = "300x150",
  type = "roadmap",
  key = process.env.REACT_APP_GOOGLE_MAPS_KEY
}) => {
  const classes = useClasses();

  return (
    <Link
      href={`https://www.google.com/maps/search/?api=1&query=${address}`}
      color="inherit"
      target="_blank"
      rel="noopener"
    >
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=${zoom}&size=${size}&markers=color:red|size:tiny|${address}&maptype=${type}&key=${key}`}
        alt=""
        className={classes.mapImage}
      />
    </Link>
  );
};

export { GoogleMapImage };
