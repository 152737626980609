import React from "react";
import { useSignup } from "../../../contexts";
import {
  MenuList,
  MenuItem,
  Paper,
  TextField,
  makeStyles,
  Box
} from "@material-ui/core";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";

const useClasses = makeStyles(theme => ({
  suggestionList: {
    position: "absolute",
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey["200"]}`
  },
  fieldContainer: {
    marginBottom: theme.spacing(1)
  }
}));

const SearchLocationInput = () => {
  const { signupData, setSignupData } = useSignup();
  const { public_name } = signupData;
  const classes = useClasses();

  const handleChange = address => {
    setSignupData({ public_name: address });
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const [result] = results;
        const { place_id, formatted_address } = result;
        setSignupData({ formatted_address: formatted_address });
        if (place_id) {
          setSignupData({ public_name: address });
          setSignupData({ place_id: place_id });
        }
      })
      .catch(error => console.error("Error", error));
  };

  const searchOptions = {
    types: ["establishment"]
  };

  const filterTypes = types => {
    if (types.includes("dentist")) return true;
    if (types.includes("doctor")) return true;
    if (types.includes("drugstore")) return true;
    if (types.includes("hospital")) return true;
    if (types.includes("pharmacy")) return true;
    if (types.includes("physiotherapist")) return true;
    if (types.includes("spa")) return true;
    if (types.includes("veterinary_care")) return true;

    return false;
  };

  return (
    <PlacesAutocomplete
      value={public_name || ""}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box position="relative" className={classes.fieldContainer}>
          <TextField
            id="location-search"
            variant="outlined"
            margin="normal"
            fullWidth
            data-cy="input-location-search"
            value={public_name}
            {...getInputProps({
              placeholder: "Provider Name (Public)",
              className: "location-search-input"
            })}
            style={{ marginBottom: 0 }}
          />
          {loading ||
            (suggestions.length > 0 && (
              <Paper>
                <MenuList className={classes.suggestionList}>
                  {loading && <Box>Loading...</Box>}
                  {suggestions
                    .filter(suggestion => filterTypes(suggestion.types))
                    .map(suggestion => (
                      <MenuItem
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item",
                          style: suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer"
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer"
                              }
                        })}
                        data-cy="menu-item-suggestion"
                        key={`suggestion-${suggestion.placeId}`}
                      >
                        <span>{suggestion.description}</span>
                      </MenuItem>
                    ))}
                </MenuList>
              </Paper>
            ))}
        </Box>
      )}
    </PlacesAutocomplete>
  );
};

export { SearchLocationInput };
