import gql from "graphql-tag";

const POST_PROVIDER_APPOINTMENT_UPDATE = gql`
  mutation postProviderAppointmentUpdate($body: Object) {
    appointment(body: $body)
      @rest(
        type: "postProviderAppointmentUpdate"
        path: "/provider/update_appointment"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      next_action
      status
      reason_code
    }
  }
`;

export { POST_PROVIDER_APPOINTMENT_UPDATE };
