const enTranslations = {
  translation: {
    provider: {
      provider: {
        title: "Location",
        intro_text: "Please select your location",
        button: "Next"
      }
    },
    show_404: {
      title: "Page not found"
    },
    appointment: {
      lookup: {
        title: "Scan Confirmation",
        button: "Change Location",
        secondary_text: "Or enter Appointment ID",
        field_label: "Appointment ID",
        button_secondary: "Submit",
        error_look_up: "Could not find appointment"
      },
      complete: {
        title: "Appointment Complete",
        intro_text: "Appointment is now complete.",
        button_check_in: "Check-in Next patient",
        button_reprint_label: "Re-print Label",
        button_rescan_vial: "Re-scan Vial",
        button_sign_out: "Sign Out",
        error_reprint_label: "Unable to re-print label"
      },
      check_in: {
        title: "Confirm Patient",
        intro_text: "Verify patient identity and location",
        error_dob: "Date of Birth doesn't match appointment record",
        check_in_error: "Unable to check patient in"
      },
      notes: {
        title: "Patient Notes",
        form: {
          injection_site: "Intramuscular injection site"
        },
        button: "End Vaccine",
        error: "Unable to check patient in",
        injection_site_options: {
          left_arm: "Left Deltoid (Arm)",
          right_arm: "Right Deltoid (Arm)",
          left_thigh: "Left Vastus Lateralis (Thigh)",
          right_thigh: "Right Vastus Lateralis (Thigh)"
        }
      },
      scan_vial: {
        title: "Scan Vial",
        error_update: "Unable to update appointment with vial id",
        error_vial_duplicate: "This is a duplicate vial ID scan.",
        error_vial_id: "A Vial ID has already been assigned to this sample.",
        error_api: "Unable to set vial id",
        error_barcode: "Invalid barcode entry.",
        secondary_text: "Successfully scanned vial",
        vial_text: "Vial Details",
        manual_text: "Or enter manually",
        button_link: {
          re_scan_vial: "Re-scan",
          vial_details: "Details"
        },
        form: {
          pharma_label: "Pharma",
          workstation: "Workstation",
          choose_label: "Choose...",
          lot_no: {
            label: "Lot #"
          },
          expiry: {
            label: "Expiry Date"
          },
          vial_id: {
            label: "Vial ID"
          }
        },
        button: "Add",
        button_next: {
          start_vaccine: "Start Vaccine",
          accept_scan: "Accept Scan"
        }
      },
      start: {
        title: "Patient Checked In",
        intro_text: "{{patientName}} was checked in successfully",
        api_error: "Unable to check patient in"
      },
      test_end: {
        title: "Test in Progress",
        intro_text: "Testing for {{patientName}} is in progress.",
        button: "End Test",
        api_error: "Unable to end test"
      },
      vax_end: {
        title: "Patient Reminder",
        intro_text: "Remind Paitent to remember the following:",
        reminder_1:
          "Stay in the observation zone for 10-15 minutes so that we can make sure you have no immediate adverse reactions.",
        reminder_2: "If you feel ill notify a staff member immediately.",
        reminder_3:
          "Remember this vaccine requires a booster. You will receive a reminder of your second appointment (which you already booked when you made this appointment).",
        reminder_4:
          "If at any time you feel ill after leaving here today please seek immediate medical attention by calling 911.",
        button: "End Visit",
        error_api: "Unable to end vaccination"
      },
      verify_insurance: {
        title: "Verify Insurance",
        button: "Re-take photo",
        error_api: "Unable to verify insurance"
      },
      capture_test_result: {
        photo_label: "Please take a photo of the result",
        result_options: {
          positive: "Positive",
          negative: "Negative"
        },
        error_api: "API error",
        title: "Capture Test Result",
        button: "Re-take photo",
        error_camera_access: "You must enable camera access on your device",
        form: {
          test_result: "Test result"
        },
        button_next: "Next",
        dialog: {
          title: "Confirm result",
          text: "Please confirm that the result is <2>{{result}}</2>",
          button_no: "No, cancel",
          button_yes: "Yes, correct"
        },
        alert_text:
          "Result verified as <2>{{result}}</2>. Click 'Next' to continue."
      }
    },
    auth: {
      login_form: {
        intro_text: "Please enter your provider token",
        button: "Sign In"
      },
      logout: {
        logout_text: "You are now logged out.",
        button: "Return to login"
      }
    },
    label: {
      label_scanner: {
        title: "Label Scanner",
        secondary_text: "Or enter manually",
        form: {
          appointment_id: "Appointment ID",
          button: "Submit"
        },
        success_text: "Successfully Scanned",
        error_api: "Error registering scan",
        success_api: "Successfully scanned"
      }
    },
    patient: {
      patient_search: {
        title: "Patient Search",
        form: {
          first_name: "First Name",
          last_name: "Last Name",
          dob: "Date of Birth",
          phone_number: "Phone Number",
          email: "Email"
        },
        button_clear: "Clear",
        button_search: "Search",
        table: {
          apt_id: "Apt. ID",
          name: "Name",
          dob: "DOB",
          phone: "Phone",
          email: "Email",
          service: "Service",
          apt_status: "Apt. Status"
        }
      },
      vial_search: {
        title: "Vial Search",
        secondary_text: "Or enter Vial ID",
        form: {
          vial_id: "Vial ID"
        },
        button_clear: "Clear",
        button_search: "Search",
        error: "No results found",
        table: {
          apt_id: "Apt. ID",
          patient_id: "Patient ID",
          name: "Name",
          dob: "DOB",
          phone: "Phone",
          email: "Email",
          vial_id: "Vial ID",
          apt_status: "Apt. Status"
        }
      }
    },
    sign_up: {
      sign_up: {
        quote:
          "GoGetVax's platform made it simple for us to quickly register and administer vaccines. The team loves it because its easy to use. Patients love it because it's seamless. Every organization needs to use GoGetVax for vaccines."
      },
      finish: {
        intro_text: "Congratulations! Your account is now created",
        secondary_text:
          "A member of our provider onboarding team will reach out to help you get ready to begin receiving appointments."
      },
      info: {
        title: "Account Information",
        secondary_text:
          "This will help us quickly generate your listing on the GoGetTested map.",
        form: {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          phone: "Phone",
          business_address: "Business Address"
        },
        error: "Sorry, there was an error submitting your application.",
        button_previous: "Previous",
        button_complete: "Complete",
        terms:
          'By clicking "Complete" you are agreeing to the GoGetVax <2>Terms and Conditions</2> and <6>Privacy Policy</6>.'
      },
      start: {
        title: "Create your GoGetTested account",
        secondary_text:
          "Input your information below and you're on your way to access the easiest way to administer testing and vaccines.",
        form: {
          legal_name: "Legal Provider Name"
        },
        button: "Next",
        sign_in: "Already have an account? <1>Sign In</1>"
      }
    },
    common: {
      error: "Sorry, an error occured",
      page_not_found: "Sorry, we couldn't find that page",
      go_home: "Go to dashboard"
    },
    components: {
      chat_teaser: {
        title: "Want to talk to a human,",
        button_label: "we're here"
      },
      patient_details: {
        dob: "Date of Birth ({{patientDob}})"
      },
      appointment_status: {
        options: {
          pending: "Pending",
          cancelled: "Cancelled",
          scheduled: "Scheduled",
          checked_in: "Checked In",
          consultation_in_progress: "In Progress",
          test_in_progress: "In Progress",
          vial_scanned: "Vial Scanned",
          test_completed: "Completed",
          record_locked: "Locked",
          vax_vial_scanned: "Vial Scanned",
          notes: "Notes",
          verify_insurance: "Verify Insurance",
          start_vax: "Start Vax",
          end_vax: "End Vax"
        }
      },
      barcodes: {
        definitions: {
          gtin: "GTIN",
          batch_lot_no: "Batch/Lot number",
          production_date: "Production date",
          best_before_date: "Best before date",
          expiration_date: "Expiration date",
          serial_no: "Serial number"
        }
      },
      resolution_code: {
        options: {
          positive_stable: "Positive stable",
          positive_unstable: "Positive unstable",
          neg_with_pmh: "Negative with primary medical history",
          neg_without_pmh: "Negative without primary medical history"
        }
      },
      location: {
        select: {
          error_api: "Could not load locations",
          form: {
            input_label: "Location",
            label: "location",
            disabled_item: "Choose..."
          },
          error_empty: "No locations available."
        }
      },
      admin: {
        header: {
          menu: {
            patient_check_in: "Patient Check-in",
            patient_search: "Patient Search",
            vial_search: "Vial Search",
            label_scanner: "Label Scanner",
            logout: "Logout"
          }
        },
        patient_card: {
          registered_date: "Registered on {{registerDate}}",
          menu_items: {
            contact: "Contact",
            appointment: "Appointment",
            notes: "Notes"
          }
        }
      },
      auth: {
        patient_auth_by_dob: {
          form: {
            verify_dob: "Verify Date of Birth"
          }
        },
        auth_0_login_button: {
          button: "Log In"
        },
        no_permission: {
          title: "Unauthorized",
          intro_text: "Sorry, you don't have permission to do this."
        }
      },
      patient: {
        consultation: {
          error: {
            review_in_progress:
              "A review for this patient is already in progress",
            api: "Sorry, an API error occured. Please try again."
          },
          button: "Add Notes",
          form: {
            notes: "Notes",
            resolution_code: {
              input_label: "Resolution Code",
              select_label: "Resolution Code"
            },
            disabled_menu_item: "Choose...",
            button_finish: "End & Save"
          },
          no_notes_text: "No previous notes found."
        },
        contact: {
          email_status: "Verified",
          phone_status: "Verified"
        }
      }
    }
  }
};

export { enTranslations };
