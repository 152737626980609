import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { TextField, Divider, Typography, Box, Button } from "@material-ui/core";
import QrReader from "react-qr-reader";
import { string, object } from "yup";
import { Loader } from "../../../components";
import { useYupValidation } from "../../../hooks";
import { POST_PROVIDER_LOOKUP_APPOINTMENT } from "../../../queries";
import {
  PROVIDER_SESSION_LOCATION,
  PROVIDER_SESSION_AUTH
} from "../../../constants";
import moment from "moment";
import { useAdminHeader } from "../../../contexts";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const validationSchema = object({
  appointment_id: string()
    .required()
    .min(6)
    .trim()
    .label("Appointment ID")
});

const focusOnErrors = createDecorator();

const ProviderAppointmentLookup = ({ setDrawerClearance, setLocation }) => {
  const history = useHistory();
  const { setTitle } = useAdminHeader();
  const { validate } = useYupValidation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("appointment.lookup.title"));
  });

  const [
    postProviderLookupAppointment,
    { loading: submittingLookupAppointment }
  ] = useMutation(POST_PROVIDER_LOOKUP_APPOINTMENT, {
    onCompleted: ({ appointment }) => {
      if (appointment.appointment_id) {
        history.push({
          pathname: `/provider/appointment-process`,
          state: {
            appointment
          }
        });
      } else {
        enqueueSnackbar(t("appointment.lookup.error_look_up"), {
          variant: "error"
        });
      }
    }
  });

  const handleLookupAppointmentById = id => {
    postProviderLookupAppointment({
      variables: {
        body: {
          appointment_id: id,
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          datetime: moment()
            .local()
            .format(),
          token: sessionStorage.getItem(PROVIDER_SESSION_AUTH)
        }
      }
    });
  };

  /**
   * Handle submit
   *
   * @param {object} data
   */
  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    handleLookupAppointmentById(data.appointment_id);
  };

  const handleScan = id => {
    if (id && !submittingLookupAppointment) {
      handleLookupAppointmentById(id);
    }
  };

  const handleScanError = error => {
    console.log(error);
  };

  const handleChangeLocation = () => {
    sessionStorage.removeItem(PROVIDER_SESSION_LOCATION);
    setLocation();
  };

  if (submittingLookupAppointment) return <Loader />;

  return (
    <>
      <Box mb={1} textAlign="right">
        <Button
          data-cy="provider-change-location"
          onClick={e => handleChangeLocation()}
        >
          {t("appointment.lookup.button")}
        </Button>
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        decorators={[focusOnErrors]}
        render={({ handleSubmit, submitErrors: errors = {} }) => (
          <form data-cy="provider-appointment-lookup" onSubmit={handleSubmit}>
            <QrReader
              delay={300}
              onError={handleScanError}
              onScan={handleScan}
            />
            <Box my={2}>
              <Divider variant="middle" />
            </Box>
            <Typography align="center" paragraph>
              {t("appointment.lookup.secondary_text")}
            </Typography>
            <Field
              name="appointment_id"
              render={({ input }) => (
                <TextField
                  {...input}
                  label={t("appointment.lookup.field_label")}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.appointment_id}
                  helperText={errors.appointment_id}
                  inputProps={{
                    "data-lpignore": "true",
                    "data-cy": "input-appointment_id"
                  }}
                  type="tel"
                />
              )}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              data-cy="button-lookup-appointment"
            >
              {t("appointment.lookup.button_secondary")}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export { ProviderAppointmentLookup };
