import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Box, Divider, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  IntroText,
  FormActionDrawer,
  Loader,
  PatientAuthByDob
} from "../../../components";
import { useAdminHeader } from "../../../contexts";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import {
  PROVIDER_SESSION_LOCATION,
  PROVIDER_SESSION_AUTH
} from "../../../constants";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProviderAppointmentCheckIn = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [dobMatchError, setDobMatchError] = useState(false);

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;

        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.check_in.check_in_error"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.check_in.title"));
  });

  const onSubmit = async data => {
    setDobMatchError(false);

    if (data.dob !== appointment.patient_dob.replace(/\//g, "")) {
      setDobMatchError(true);
      return false;
    }

    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: appointment.next_action,
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box data-cy="provider-patient-confirm">
            <IntroText>{t("appointment.check_in.intro_text")}</IntroText>

            <Box my={2}>
              <Divider variant="middle" />
            </Box>
            <IntroText>
              {appointment.service_selection_codes &&
                Array.isArray(appointment.service_selection_codes) && (
                  <>
                    {appointment.service_selection_codes.map(service_code => (
                      <Box key={service_code}>
                        <strong>{service_code}</strong>
                      </Box>
                    ))}
                  </>
                )}
              {appointment.location && (
                <Box>
                  <strong data-cy="provider-patient-confirm-location-name">
                    {appointment.location}
                  </strong>
                </Box>
              )}
              {appointment.date && (
                <Box>
                  <strong>{appointment.date}</strong>
                </Box>
              )}
            </IntroText>
            <Box my={2}>
              <Divider variant="middle" />
            </Box>
            <IntroText>
              <Box>
                <strong data-cy="provider-patient-confirm-patient-name">
                  {appointment.patient_name}
                </strong>
              </Box>
              <Box>
                <strong data-cy="provider-patient-confirm-patient-gender">
                  {appointment.patient_gender}
                </strong>
              </Box>
              {appointment.patient_address && (
                <Box>
                  <strong data-cy="provider-patient-confirm-patient-address">
                    {appointment.patient_address}
                  </strong>
                </Box>
              )}
            </IntroText>
            {dobMatchError && (
              <Typography
                data-cy="patient-confirm-dob-error"
                color="error"
                paragraph
              >
                {t("appointment.check_in.error_dob")}
              </Typography>
            )}
            <PatientAuthByDob handleOnSubmit={onSubmit}>
              <FormActionDrawer
                setDrawerClearance={setDrawerClearance}
                nextButtonLabel="Submit"
              />
            </PatientAuthByDob>
          </Box>
        </>
      )}
    </>
  );
};

export { ProviderAppointmentCheckIn };
