const SERVICE_COVID_19_TEST = "COVID_19_TEST";
const SERVICE_FLU_SHOT = "FLU_SHOT";
const SERVICE_CONSULT = "CONSULT";
const SERVICE_COVID_19_VAX = "COVID_19_VAX";
const SERVICE_COVID_19_VACCINE_PFIZER_1 = "COVID_19_VACCINE_PFIZER_1";
const SERVICE_COVID_19_VACCINE_PFIZER_2 = "COVID_19_VACCINE_PFIZER_2";
const SERVICE_COVID_19_VACCINE_MODERNA_1 = "COVID_19_VACCINE_MODERNA_1";
const SERVICE_COVID_19_VACCINE_MODERNA_2 = "COVID_19_VACCINE_MODERNA_2";
const SERVICE_COVID_19_TEST_ANTIGEN = "COVID_19_TEST_ANTIGEN";
const SERVICE_COVID_19_TEST_MEXICO = "COVID_19_TEST_MEXICO";
const SERVICE_COVID_19_TEST_MEXICO_ANTIGEN = "COVID_19_TEST_MEXICO_ANTIGEN";
const SERVICE_COVID_19_VACCINE_JNJ = "COVID_19_VACCINE_JNJ";
const SERVICE_COVID_19_TEST_KS = "COVID_19_TEST_KS";
const SERVICE_COVID_19_TEST_KS_ANTIGEN = "COVID_19_TEST_KS_ANTIGEN";
const SERVICE_COVID_19_TEST_NV = "COVID_19_TEST_NV";
const SERVICE_COVID_19_TEST_NV_ANTIGEN = "COVID_19_TEST_ANTIGEN_NV";

export {
  SERVICE_COVID_19_TEST,
  SERVICE_FLU_SHOT,
  SERVICE_CONSULT,
  SERVICE_COVID_19_VAX,
  SERVICE_COVID_19_VACCINE_PFIZER_1,
  SERVICE_COVID_19_VACCINE_PFIZER_2,
  SERVICE_COVID_19_VACCINE_MODERNA_1,
  SERVICE_COVID_19_VACCINE_MODERNA_2,
  SERVICE_COVID_19_TEST_ANTIGEN,
  SERVICE_COVID_19_TEST_MEXICO,
  SERVICE_COVID_19_TEST_MEXICO_ANTIGEN,
  SERVICE_COVID_19_VACCINE_JNJ,
  SERVICE_COVID_19_TEST_KS,
  SERVICE_COVID_19_TEST_KS_ANTIGEN,
  SERVICE_COVID_19_TEST_NV,
  SERVICE_COVID_19_TEST_NV_ANTIGEN
};
