import { PROVIDER_SESSION_PREFERENCES, LANGUAGES } from "../constants";

const PREFERENCE_DEFAULTS = {
  language: LANGUAGES[0].key
};

const usePreferences = () => {
  const getPreferences = () =>
    localStorage.getItem(PROVIDER_SESSION_PREFERENCES)
      ? JSON.parse(localStorage.getItem(PROVIDER_SESSION_PREFERENCES))
      : PREFERENCE_DEFAULTS;

  const updatePreferences = newPreferences => {
    const currentPreferences = getPreferences();

    localStorage.setItem(
      PROVIDER_SESSION_PREFERENCES,
      JSON.stringify({
        ...currentPreferences,
        ...newPreferences
      })
    );
  };

  const { language = "" } = getPreferences();

  return {
    language,
    updatePreferences
  };
};

export { usePreferences };
