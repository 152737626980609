import moment from "moment";

const validDateOfBirth = dob => {
  if (dob.length === 0) {
    return true;
  }

  if (dob.length === 8) {
    let date = moment(dob, "MMDDYYYY");

    let valid = date.isValid();
    let tooOld = date.isBefore("1900-01-01");
    let inFuture = date.isAfter(moment());

    return valid && !tooOld && !inFuture;
  }

  return false;
};

export { validDateOfBirth };
