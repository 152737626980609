import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme
} from "@material-ui/core";
import { ChatTeaser, LogoGGV, LogoGGT } from "../../components";
import { Helmet } from "react-helmet";

const useClasses = makeStyles({
  grid: {
    minHeight: "100vh"
  }
});

const ProviderLoginWrapper = ({ children, showLogo = true }) => {
  const classes = useClasses();
  const theme = useTheme();

  return (
    <>
      <Helmet
        bodyAttributes={{
          style: `background-color: ${theme.palette.secondary.main}`
        }}
      />
      <Box className={classes.container}>
        <Container maxWidth="xs" disableGutters className={classes.container}>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.grid}
          >
            {showLogo && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align="center" gutterBottom>
                      <LogoGGV
                        textColor="#fff"
                        bubbleColor="#fff"
                        bubbleTextColor="#333"
                        tickColor="#fff"
                        height="40px"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" gutterBottom>
                      <LogoGGT
                        height="40px"
                        primaryColor="#fff"
                        secondaryColor="#333"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {children}
              <Box py={2} textAlign="center" color={theme.palette.common.white}>
                <ChatTeaser />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export { ProviderLoginWrapper };
