import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useClasses = makeStyles({
  intro: {
    lineHeight: "1.4"
  }
});

const IntroText = ({ children }) => {
  const classes = useClasses();

  return (
    <Box px={1}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.intro}
      >
        {children}
      </Typography>
    </Box>
  );
};

export { IntroText };
