import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useAdminHeader } from "../../../contexts";
import { Camera, FormActionDrawer } from "../../../components";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import { hasGetUserMedia } from "../../../utils";
import {
  PROVIDER_SESSION_AUTH,
  PROVIDER_SESSION_LOCATION
} from "../../../constants";
import { useTranslation } from "react-i18next";

const ProviderAppointmentVerifyInsurance = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { t } = useTranslation();
  const [photo, setPhoto] = useState();

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;
        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.verify_insurance.error_api"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.verify_insurance.title"));
  });

  /**
   * Skip step if no camera availability or set previously taken photo
   */
  useEffect(() => {
    if (!hasGetUserMedia()) handleNext();

    if (appointment.insurance_photo) {
      setPhoto(appointment.insurance_photo);
    }
  }, [appointment]);

  /**
   * Handle take photo
   * @param {string} dataUri
   */
  const handleTakePhoto = dataUri => {
    if (dataUri) {
      setPhoto(dataUri);
    }
  };

  /**
   * Handle re-take photo
   */
  const handleRetakePhoto = () => {
    setPhoto();
  };

  /**
   * Handle next button
   */
  const handleNext = () => {
    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: appointment.next_action,
          insurance_photo: photo || "",
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  return (
    <Box data-cy="provider-patient-verify-insurance">
      {photo ? (
        <Box my={1} textAlign="center">
          <Button
            data-cy="button-retake-photo"
            onClick={e => handleRetakePhoto()}
          >
            {t("appointment.verify_insurance.button")}
          </Button>
        </Box>
      ) : null}
      <Camera photo={photo} onTakePhoto={handleTakePhoto} />
      {photo && (
        <FormActionDrawer
          setDrawerClearance={setDrawerClearance}
          handleNext={e => handleNext()}
        />
      )}
    </Box>
  );
};

export { ProviderAppointmentVerifyInsurance };
