import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  Avatar
} from "@material-ui/core";
import { NavigateBefore, Menu, Person } from "@material-ui/icons";
import { theme } from "../../theme";
import { LogoGGV, LogoGGT } from "../Logo";
import { useAuth0 } from "@auth0/auth0-react";
import { PROVIDER_SESSION_AUTH } from "../../constants";
import { LanguageToggle } from "../Common";
import { useLanguages } from "../../contexts";
import { useTranslation } from "react-i18next";

const useClasses = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  drawerPaper: {
    backgroundColor: theme.palette.grey["900"]
  },
  drawerInner: {
    width: "260px"
  },
  menu: {
    color: theme.palette.common.white
  },
  listItem: {
    borderLeft: `3px solid ${theme.palette.grey["900"]}`
  },
  listItemSelected: {
    borderLeftColor: theme.palette.primary.main
  },
  logo: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)"
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.appBar,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  languageToggle: {
    textAlign: "right",
    paddingRight: theme.spacing(1)
  }
}));

const HeaderAction = ({ setOpenMenu }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      {location.pathname !== "/provider" ? (
        <IconButton aria-label="back" onClick={e => history.goBack()}>
          <NavigateBefore color="secondary" fontSize="large" />
        </IconButton>
      ) : (
        <IconButton aria-label="menu" onClick={e => setOpenMenu(true)}>
          <Menu color="secondary" fontSize="large" />
        </IconButton>
      )}
    </>
  );
};

const AdminHeader = ({ title = "", user = {}, setHeight }) => {
  const classes = useClasses();
  const history = useHistory();
  const location = useLocation();
  const headerRef = useRef(null);
  const { logout } = useAuth0();
  const [openMenu, setOpenMenu] = useState(false);
  const { setLanguage, language } = useLanguages();
  const { t } = useTranslation();

  const MenuItems = [
    {
      label: t("components.admin.header.menu.patient_check_in"),
      onClick: () => history.push(`/provider`),
      active: true
    },
    {
      label: t("components.admin.header.menu.patient_search"),
      onClick: () => history.push(`/patient/search`),
      active: true
    },
    {
      label: t("components.admin.header.menu.vial_search"),
      onClick: () => history.push(`/vial/scanner`),
      active: true
    },
    {
      label: t("components.admin.header.menu.label_scanner"),
      onClick: () => history.push(`/provider/label/scanner`),
      active: true
    },
    {
      label: t("components.admin.header.menu.logout"),
      onClick: () => {
        logout({ returnTo: `${window.location.origin}/provider/logout` });
        sessionStorage.removeItem(PROVIDER_SESSION_AUTH);
      },
      active: true
    }
  ];

  const activeMenuItems = menuItem => menuItem.active;

  const getListItemClass = path => {
    if (location.pathname === path) {
      return `${classes.listItem} ${classes.listItemSelected}`;
    }

    return classes.listItem;
  };

  useLayoutEffect(() => {
    if (!headerRef.current) return;
    setHeight(headerRef.current.clientHeight);
  });

  return (
    <>
      <Box p={1} className={classes.header} ref={headerRef}>
        <Container maxWidth="xs" disableGutters>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <HeaderAction setOpenMenu={setOpenMenu} />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h2" component="h1" noWrap>
                {title}
              </Typography>
            </Grid>
            <Grid item xs className={classes.languageToggle}>
              <LanguageToggle
                language={language}
                handleToggleLanguage={(e, value) => setLanguage(value)}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Drawer
        anchor="left"
        open={openMenu}
        onClose={e => setOpenMenu(false)}
        PaperProps={{ className: classes.drawerPaper }}
      >
        <Box className={classes.drawerInner}>
          <Grid
            container
            spacing={1}
            direction="column"
            justify="space-between"
            wrap="nowrap"
          >
            <Grid item xs>
              <Box
                textAlign="center"
                py={8}
                px={4}
                color={theme.palette.common.white}
              >
                <Grid container justify="center">
                  <Grid item>
                    <Box py={2}>
                      {user.picture ? (
                        <Avatar src={user.picture} />
                      ) : (
                        <Avatar>
                          <Person />
                        </Avatar>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Typography color="inherit" variant="h4" gutterBottom>
                  {user.email ? user.email : "GGT Admin"}
                </Typography>
              </Box>
            </Grid>

            {MenuItems.filter(activeMenuItems).length > 0 && (
              <Grid item xs>
                <List component="nav" className={classes.menu}>
                  {MenuItems.filter(activeMenuItems).map((item, index) => (
                    <ListItem
                      key={`menu-item-${index}`}
                      divider
                      button
                      onClick={e => {
                        setOpenMenu(false);
                        item.onClick();
                      }}
                      className={getListItemClass(item.path)}
                    >
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ color: "inherit" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
          <Box className={classes.logo} py={4}>
            <Typography gutterBottom>
              <LogoGGV
                textColor="#fff"
                bubbleColor="#fff"
                bubbleTextColor="#333"
                tickColor="#fff"
                height="40px"
              />
            </Typography>
            <LogoGGT height="40px" primaryColor="#fff" secondaryColor="#333" />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

AdminHeader.propTypes = {
  title: PropTypes.string,
  setHeight: PropTypes.func.isRequired
};

export { AdminHeader };
