import jwtDecode from "jwt-decode";
import { PROVIDER_SESSION_AUTH } from "../constants";

const useProviderAuth = () => {
  let decodedToken = { id: 0 };
  let jwt = sessionStorage.getItem(PROVIDER_SESSION_AUTH);

  if (jwt) {
    decodedToken = { ...decodedToken, ...jwtDecode(jwt) };
  }

  return { token: jwt, decodedToken };
};

export { useProviderAuth };
