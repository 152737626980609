import React, { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { useAdminHeader } from "../../contexts";
import {
  LocationSelect,
  Sheet,
  SheetContent,
  IntroText,
  FormActionDrawer
} from "../../components";
import { ProviderAppointmentLookup } from "../Appointment/children";
import { PROVIDER_SESSION_LOCATION } from "../../constants";
import { Form, Field } from "react-final-form";
import { string, object } from "yup";
import { useYupValidation } from "../../hooks";
import { useTranslation } from "react-i18next";

const validationSchema = object({
  location: string()
    .required()
    .label("Location")
});

const Provider = () => {
  const { setTitle } = useAdminHeader();
  const { validate } = useYupValidation();
  const { t } = useTranslation();

  const [drawerClearance, setDrawerClearance] = useState(0);
  const [location, setLocation] = useState();

  useEffect(() => {
    setTitle(t("provider.provider.title"));
  });

  /**
   * Retrieve location from session storage
   */
  useEffect(() => {
    const savedLocation = sessionStorage.getItem(PROVIDER_SESSION_LOCATION);

    if (savedLocation) setLocation(savedLocation);
  }, []);

  /**
   * Save location to session storage
   */
  useEffect(() => {
    if (location) sessionStorage.setItem(PROVIDER_SESSION_LOCATION, location);
  }, [location]);

  const validateForm = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    setLocation(data.location);
  };

  return (
    <Sheet
      data-cy="provider-view"
      style={{ paddingBottom: `${drawerClearance}px` }}
    >
      <SheetContent>
        <Box mb={2}>
          <Paper>
            <Box p={2}>
              {location ? (
                <ProviderAppointmentLookup
                  setDrawerClearance={setDrawerClearance}
                  setLocation={setLocation}
                />
              ) : (
                <Form
                  onSubmit={validateForm}
                  initialValues={{}}
                  render={({ handleSubmit, submitErrors: errors = {} }) => (
                    <form onSubmit={handleSubmit}>
                      <IntroText>{t("provider.provider.intro_text")}</IntroText>
                      <Field
                        name="location"
                        render={({ input }) => (
                          <LocationSelect
                            errors={errors}
                            input={input}
                            filterResults={result =>
                              result.status === "enabled"
                            }
                          />
                        )}
                      />
                      <FormActionDrawer
                        setDrawerClearance={setDrawerClearance}
                        nextButtonLabel={t("provider.provider.button")}
                      />
                    </form>
                  )}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </SheetContent>
    </Sheet>
  );
};

export { Provider };
