import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { esTranslations } from "./es";
import { enTranslations } from "./en";

i18n.use(initReactI18next).init({
  resources: {
    en: enTranslations,
    es: esTranslations
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
