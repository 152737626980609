import gql from "graphql-tag";

const POST_PROVIDER_CARE_BEGIN_CONSULTATION = gql`
  mutation postProviderCareBeginConsultation($body: Object) {
    consultation(body: $body)
      @rest(
        type: "postProviderCareBeginConsultation"
        path: "/care_provider/begin_consultation"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      status
      results
    }
  }
`;

export { POST_PROVIDER_CARE_BEGIN_CONSULTATION };
