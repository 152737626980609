import gql from "graphql-tag";

const POST_PROVIDER_LABEL_SCAN = gql`
  mutation postProviderLabelScan($body: Object) {
    label(body: $body)
      @rest(
        type: "postProviderAppointmentUpdate"
        path: "/provider/scan_label"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      status
    }
  }
`;

export { POST_PROVIDER_LABEL_SCAN };
