import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Box, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { IntroText, FormActionDrawer, Loader } from "../../../components";
import { useAdminHeader } from "../../../contexts";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import {
  PROVIDER_SESSION_LOCATION,
  PROVIDER_SESSION_AUTH
} from "../../../constants";
import { useTranslation } from "react-i18next";

const ProviderAppointmentVaxEnd = ({
  appointment,
  setAppointment,
  setDrawerClearance
}) => {
  const { setTitle } = useAdminHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;

        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.vax_end.error_api"), {
          variant: "error"
        });
      }
    }
  );

  useEffect(() => {
    setTitle(t("appointment.vax_end.title"));
  });

  const handleNext = () => {
    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: appointment.next_action,
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box data-cy="provider-appointment-vax-end">
            <IntroText>{t("appointment.vax_end.intro_text")}</IntroText>
            <ul>
              <li>
                <Typography>{t("appointment.vax_end.reminder_1")}</Typography>
              </li>
              <li>
                <Typography>{t("appointment.vax_end.reminder_2")}</Typography>
              </li>
              <li>
                <Typography>{t("appointment.vax_end.reminder_3")}</Typography>
              </li>
              <li>
                <Typography>{t("appointment.vax_end.reminder_4")}</Typography>
              </li>
            </ul>
          </Box>
          <FormActionDrawer
            setDrawerClearance={setDrawerClearance}
            handleNext={e => handleNext()}
            nextButtonLabel={t("appointment.vax_end.button")}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export { ProviderAppointmentVaxEnd };
