const LANGUAGES = [
  {
    key: "en",
    label: "English"
  },
  {
    key: "es",
    label: "Español"
  }
];

export { LANGUAGES };
