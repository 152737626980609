import gql from "graphql-tag";

const POST_PROVIDER_CARE_END_CONSULTATION = gql`
  mutation postProviderCareEndConsultation($body: Object) {
    consultation(body: $body)
      @rest(
        type: "postProviderCareEndConsultation"
        path: "/care_provider/end_consultation"
        endpoint: "v1"
        method: "POST"
        bodyKey: "body"
      ) {
      status
      results
    }
  }
`;

export { POST_PROVIDER_CARE_END_CONSULTATION };
