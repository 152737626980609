const APPOINTMENT_STATUS_PENDING = "pending";
const APPOINTMENT_STATUS_CANCELLED = "cancelled";
const APPOINTMENT_STATUS_SCHEDULED = "scheduled";
const APPOINTMENT_STATUS_CHECKED_IN = "checked_in";
const APPOINTMENT_STATUS_PRE_CONSULTATION_IN_PROGRESS =
  "pre_consultation_in_progress";
const APPOINTMENT_STATUS_TEST_IN_PROGRESS = "test_in_progress";
const APPOINTMENT_STATUS_VIAL_SCANNED = "vial_scanned";
const APPOINTMENT_STATUS_TEST_COMPLETED = "test_completed";
const APPOINTMENT_STATUS_RECORD_LOCKED = "record_locked";
const APPOINTMENT_STATUS_SCAN_VIAL_VAX = "scan_vial_vax";
const APPOINTMENT_STATUS_NOTES = "notes";
const APPOINTMENT_STATUS_VERIFY_INSURANCE = "verify_insurance";
const APPOINTMENT_STATUS_START_VAX = "start_vax";
const APPOINTMENT_STATUS_END_VAX = "end_vax";

let APPOINTMENT_STATUS = {};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_PENDING] = {
  label_translation_key: "components.appointment_status.options.pending",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_CANCELLED] = {
  label_translation_key: "components.appointment_status.options.cancelled",
  severity: "warning"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_SCHEDULED] = {
  label_translation_key: "components.appointment_status.options.scheduled",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_CHECKED_IN] = {
  label_translation_key: "components.appointment_status.options.checked_in",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_PRE_CONSULTATION_IN_PROGRESS] = {
  label_translation_key:
    "components.appointment_status.options.consultation_in_progress",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_TEST_IN_PROGRESS] = {
  label_translation_key:
    "components.appointment_status.options.test_in_progress",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_VIAL_SCANNED] = {
  label_translation_key: "components.appointment_status.options.vial_scanned",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_TEST_COMPLETED] = {
  label_translation_key: "components.appointment_status.options.test_completed",
  severity: "success"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_RECORD_LOCKED] = {
  label_translation_key: "components.appointment_status.options.record_locked",
  severity: "warning"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_SCAN_VIAL_VAX] = {
  label_translation_key:
    "components.appointment_status.options.vax_vial_scanned",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_NOTES] = {
  label_translation_key: "components.appointment_status.options.notes",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_VERIFY_INSURANCE] = {
  label_translation_key:
    "components.appointment_status.options.verify_insurance",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_START_VAX] = {
  label_translation_key: "components.appointment_status.options.start_vax",
  severity: "info"
};

APPOINTMENT_STATUS[APPOINTMENT_STATUS_END_VAX] = {
  label_translation_key: "components.appointment_status.options.end_vax",
  severity: "info"
};

export {
  APPOINTMENT_STATUS_PENDING,
  APPOINTMENT_STATUS_CANCELLED,
  APPOINTMENT_STATUS_SCHEDULED,
  APPOINTMENT_STATUS_CHECKED_IN,
  APPOINTMENT_STATUS_PRE_CONSULTATION_IN_PROGRESS,
  APPOINTMENT_STATUS_TEST_IN_PROGRESS,
  APPOINTMENT_STATUS_VIAL_SCANNED,
  APPOINTMENT_STATUS_TEST_COMPLETED,
  APPOINTMENT_STATUS_RECORD_LOCKED,
  APPOINTMENT_STATUS_SCAN_VIAL_VAX,
  APPOINTMENT_STATUS_NOTES,
  APPOINTMENT_STATUS_VERIFY_INSURANCE,
  APPOINTMENT_STATUS_START_VAX,
  APPOINTMENT_STATUS_END_VAX,
  APPOINTMENT_STATUS
};
