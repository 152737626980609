import React, { useEffect } from "react";
import { Box, Button, Divider } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { IntroText, ButtonLoader } from "../../../components";
import { useAdminHeader } from "../../../contexts";
import {
  PROVIDER_SESSION_AUTH,
  PROVIDER_SESSION_LOCATION
} from "../../../constants";
import { POST_PROVIDER_APPOINTMENT_UPDATE } from "../../../queries";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const ProviderAppointmentComplete = ({
  appointment,
  setAppointment,
  isVaxAppointment = false,
  isMexicoAppointment = false
}) => {
  const { setTitle } = useAdminHeader();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("appointment.complete.title"));
  });

  const [postProviderAppointmentUpdate, { loading }] = useMutation(
    POST_PROVIDER_APPOINTMENT_UPDATE,
    {
      onCompleted: ({ appointment }) => {
        const { next_action = "" } = appointment;

        setAppointment(appointment => ({
          ...appointment,
          next_action
        }));
      },
      onError: () => {
        enqueueSnackbar(t("appointment.complete.error_reprint_label"), {
          variant: "error"
        });
      }
    }
  );

  const handleNextPatient = () => {
    setAppointment();
    history.push(`/provider`);
  };

  const handleSignOut = () => {
    sessionStorage.removeItem(PROVIDER_SESSION_AUTH);
    history.push(`/provider`);
  };

  const handlePrintLabel = () => {
    postProviderAppointmentUpdate({
      variables: {
        body: {
          appointment_id: appointment.appointment_id,
          action: "reprint",
          auth_token: sessionStorage.getItem(PROVIDER_SESSION_AUTH),
          operator_location_id: sessionStorage.getItem(
            PROVIDER_SESSION_LOCATION
          ),
          service_code: appointment.service_selection_codes[0] || ""
        }
      }
    });
  };

  const handleScanVial = () => {
    setAppointment(appointment => ({
      ...appointment,
      next_action: isVaxAppointment ? "scan_vial_vax" : "scan_vial"
    }));
  };

  return (
    <>
      <Box data-cy="provider-patient-appointment-complete">
        <IntroText>{t("appointment.complete.intro_text")}</IntroText>
        <Button
          color="primary"
          size="large"
          fullWidth
          variant="contained"
          onClick={e => handleNextPatient()}
          data-cy="button-next-patient"
        >
          {t("appointment.complete.button_check_in")}
        </Button>
        <Box my={2}>
          <Divider variant="middle" />
        </Box>
        {!isVaxAppointment && !isMexicoAppointment && (
          <>
            <Box my={2} data-cy="button-reprint-label">
              <ButtonLoader
                color="secondary"
                size="large"
                fullWidth
                variant="contained"
                onClick={e => handlePrintLabel()}
                data-cy="button-reprint-label"
                loading={loading}
              >
                {t("appointment.complete.button_reprint_label")}
              </ButtonLoader>
            </Box>
            <ButtonLoader
              color="secondary"
              size="large"
              fullWidth
              variant="contained"
              onClick={e => handleScanVial()}
              data-cy="button-rescan-vial"
              loading={loading}
            >
              {t("appointment.complete.button_rescan_vial")}
            </ButtonLoader>
          </>
        )}
        <Box my={2}>
          <Divider variant="middle" />
        </Box>
        <Button
          color="secondary"
          size="large"
          fullWidth
          variant="outlined"
          onClick={e => handleSignOut()}
        >
          {t("appointment.complete.button_sign_out")}
        </Button>
      </Box>
    </>
  );
};

export { ProviderAppointmentComplete };
