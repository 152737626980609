import React from "react";
import PropTypes from "prop-types";
import { Link, makeStyles, ButtonBase } from "@material-ui/core";

const useClasses = makeStyles({
  buttonLink: {
    verticalAlign: "baseline",
    letterSpacing: "inherit",
    fontSize: "inherit"
  }
});

const ButtonLink = ({ label, onClick, ...other }) => {
  const classes = useClasses();
  return (
    <Link
      component={ButtonBase}
      variant="body2"
      color="inherit"
      onClick={onClick}
      className={classes.buttonLink}
      {...other}
    >
      {label}
    </Link>
  );
};

ButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export { ButtonLink };
