import React from "react";
import { Box, Paper } from "@material-ui/core";
import { ProviderLoginWrapper } from "./ProviderLoginWrapper";
import { Auth0LoginButton } from "../../components";

const ProviderLogin = () => {
  return (
    <ProviderLoginWrapper showLogo={true}>
      <Box p={2}>
        <Box mx={2}>
          <Paper>
            <Box p={2}>
              <Auth0LoginButton />
            </Box>
          </Paper>
        </Box>
      </Box>
    </ProviderLoginWrapper>
  );
};

export { ProviderLogin };
