import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  POST_PROVIDER_CARE_BEGIN_CONSULTATION,
  POST_PROVIDER_CARE_END_CONSULTATION
} from "../../queries";
import { ButtonLoader } from "../Common";
import { useProviderAuth, useYupValidation } from "../../hooks";
import { useSnackbar } from "notistack";
import { Form, Field } from "react-final-form";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select
} from "@material-ui/core";
import createDecorator from "final-form-focus";
import { string, object } from "yup";
import { Add, Save } from "@material-ui/icons";
import moment from "moment";
import {
  RESOLUTION_CODE_COVID_19_TEST,
  CONSULTATION_CODE_PRE_COVID,
  CONSULTATION_CODE_VAX,
  RESOLUTION_CODE_NEG_WITHOUT_PMH
} from "../../constants";
import { isVaxAppointment } from "../../utils";
import { useTranslation } from "react-i18next";

const focusOnErrors = createDecorator();

const validationSchema = object({
  note: string()
    .required()
    .trim()
    .label("Notes"),
  resolution_code: string()
    .required()
    .label("Resolution code")
});

const getConsultationCode = serviceCode => {
  if (isVaxAppointment([serviceCode])) {
    return CONSULTATION_CODE_VAX;
  } else {
    // TODO: Send correct code based on appointment status
    return CONSULTATION_CODE_PRE_COVID;
  }
};

const PatientConsultationNote = ({ consultation }) => {
  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box p={1}>
          <Typography variant="body1">
            {consultation.consultation_notes}
          </Typography>
        </Box>
      </Paper>
      <Box pt={1}>
        <Typography align="right" variant="body2">
          <em>
            By {consultation.provider_name} on{" "}
            {moment(consultation.consultation_start_dt).format(
              "ddd, MMM do YYYY"
            )}
          </em>
        </Typography>
      </Box>
    </Box>
  );
};

const PatientConsultation = ({
  appointmentId,
  serviceCode,
  onSuccess = () => {},
  pastConsultations = []
}) => {
  const { validate } = useYupValidation();
  const { enqueueSnackbar } = useSnackbar();
  const { decodedToken } = useProviderAuth();
  const { t } = useTranslation();

  const [consultationId, setConsultationId] = useState("");
  const [allConsultations, setAllConsultations] = useState([]);

  useEffect(() => {
    if (Array.isArray(pastConsultations)) {
      setAllConsultations(prev => [...prev, ...pastConsultations]);
    }
  }, [pastConsultations]);

  const [
    postBeginConsultation,
    { loading: loadingBeginConsultation }
  ] = useMutation(POST_PROVIDER_CARE_BEGIN_CONSULTATION, {
    onCompleted: ({ consultation }) => {
      if (
        consultation &&
        consultation.status &&
        consultation.status === "success" &&
        consultation.results &&
        consultation.results.consultation_id
      ) {
        setConsultationId(consultation.results.consultation_id);
      } else {
        enqueueSnackbar(
          t("components.patient.consultation.error.review_in_progress"),
          {
            variant: "warning"
          }
        );
      }
    },
    onError: () => {
      enqueueSnackbar(t("components.patient.consultation.error.api"), {
        variant: "error"
      });
    }
  });

  const [
    postEndConsultation,
    { loading: loadingEndConsultation }
  ] = useMutation(POST_PROVIDER_CARE_END_CONSULTATION, {
    onCompleted: ({ consultation }) => {
      if (
        consultation &&
        consultation.status &&
        consultation.status === "success" &&
        Array.isArray(consultation.results)
      ) {
        setConsultationId("");

        const [patient] = consultation.results;

        if (Array.isArray(patient.consultations)) {
          setAllConsultations(patient.consultations);
        }

        onSuccess(consultation);
      }
    }
  });

  const handleBeginConsultation = () => {
    postBeginConsultation({
      variables: {
        body: {
          test_id: appointmentId,
          user_id: decodedToken.sub,
          appointment_id: appointmentId
        }
      }
    });
  };

  /**
   * Handle submit
   *
   * @param {object} data
   */
  const onSubmit = async data => {
    const errors = await validate(validationSchema)(data);

    if (errors) return errors;

    postEndConsultation({
      variables: {
        body: {
          consultation_id: consultationId,
          note: data.note,
          test_id: appointmentId,
          consultation_type_code: getConsultationCode(serviceCode),
          resolution_code: data.resolution_code
        }
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {!consultationId ? (
          <ButtonLoader
            onClick={() => handleBeginConsultation()}
            loading={loadingBeginConsultation}
            variant="contained"
            color="primary"
            startIcon={<Add />}
            fullWidth
          >
            {t("components.patient.consultation.button")}
          </ButtonLoader>
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={{ resolution_code: RESOLUTION_CODE_NEG_WITHOUT_PMH }}
            decorators={[focusOnErrors]}
            render={({ handleSubmit, submitErrors: errors = {} }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="note"
                  render={({ input }) => (
                    <TextField
                      {...input}
                      data-cy="input-note"
                      variant="outlined"
                      label={t("components.patient.consultation.form.notes")}
                      multiline
                      rows={4}
                      fullWidth
                      error={!!errors.note}
                      helperText={errors.note}
                    />
                  )}
                />
                {!isVaxAppointment([serviceCode]) && (
                  <Field
                    name="resolution_code"
                    render={({ input }) => (
                      <FormControl margin="normal" fullWidth variant="outlined">
                        <InputLabel id="vial-resolution_code">
                          {t(
                            "components.patient.consultation.form.resolution_code.input_label"
                          )}
                        </InputLabel>
                        <Select
                          {...input}
                          labelId="vial-resolution_code"
                          id="vial-resolution_code"
                          label={t(
                            "components.patient.consultation.form.resolution_code.select_label"
                          )}
                          fullWidth
                          data-cy="input-resolution_code"
                        >
                          <MenuItem disabled value="">
                            {t(
                              "components.patient.consultation.form.disabled_menu_item"
                            )}
                          </MenuItem>
                          {RESOLUTION_CODE_COVID_19_TEST.map(
                            (option, index) => (
                              <MenuItem
                                key={`option-${index}`}
                                value={option.value}
                                data-cy="input-resolution_code-option"
                              >
                                {t(option.label_translation_key)}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {!!errors.resolution_code && (
                          <FormHelperText error={true}>
                            {errors.resolution_code}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
                <Box mt={1}>
                  <ButtonLoader
                    loading={loadingEndConsultation}
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    fullWidth
                  >
                    {t("components.patient.consultation.form.button_finish")}
                  </ButtonLoader>
                </Box>
              </form>
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {allConsultations.length > 0 ? (
          <>
            {allConsultations.map(consultation => (
              <PatientConsultationNote
                key={consultation.consultation_id}
                consultation={consultation}
              />
            ))}
          </>
        ) : (
          <Typography>
            {t("components.patient.consultation.no_notes_text")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export { PatientConsultation };
