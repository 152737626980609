import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { AddCircle, CheckCircle } from "@material-ui/icons";
import { useEffect } from "react";

const useClasses = makeStyles(theme => ({
  iconChecked: {
    color: theme.palette.custom.green
  }
}));

const CheckboxList = ({
  options,
  allowMultiple = false,
  initialValues,
  onChange = () => {}
}) => {
  const classes = useClasses();
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    setSelectedValue(initialValues);
  }, []);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  /**
   * Handles click
   *
   * @param {*} value
   */
  const handleClick = value => {
    if (allowMultiple) {
      if (selectedValue.indexOf(value) > -1) {
        setSelectedValue(values => values.filter(item => item !== value));
      } else {
        setSelectedValue(values => [...values, value]);
      }
    } else {
      setSelectedValue(prevValue => (value === prevValue ? undefined : value));
    }
  };

  /**
   * Handles showing selected items
   *
   * @param {*} value
   */
  const isSelected = value => {
    if (allowMultiple) {
      return selectedValue.includes(value);
    } else {
      return value === selectedValue;
    }
  };

  return (
    <List data-cy="checkbox-list">
      {options.map((option, index) => (
        <ListItem key={`location-${index}`} disableGutters divider>
          <ListItemText primary={option.label} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={e => handleClick(option.value)}
              data-cy={`checkbox-list-button-${option.value}`}
            >
              {isSelected(option.value) ? (
                <CheckCircle
                  data-cy="checkbox-item-checked"
                  className={classes.iconChecked}
                />
              ) : (
                <AddCircle data-cy="checkbox-item-unchecked" color="disabled" />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

CheckboxList.propTypes = {
  initialValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  options: PropTypes.array.isRequired,
  allowMultiple: PropTypes.bool,
  onChange: PropTypes.func
};

export { CheckboxList };
