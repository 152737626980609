const SERVICE_CODE_COVID_19_TEST = "COVID_19_TEST";
const SERVICE_CODE_FLU_SHOT = "FLU_SHOT";
const SERVICE_CODE_CONSULT = "CONSULT";
const SERVICE_CODE_COVID_19_VAX = "COVID_19_VAX";
const SERVICE_CODE_COVID_19_VACCINE_PFIZER_1 = "COVID_19_VACCINE_PFIZER_1";
const SERVICE_CODE_COVID_19_VACCINE_PFIZER_2 = "COVID_19_VACCINE_PFIZER_2";
const SERVICE_CODE_COVID_19_VACCINE_MODERNA_1 = "COVID_19_VACCINE_MODERNA_1";
const SERVICE_CODE_COVID_19_VACCINE_MODERNA_2 = "COVID_19_VACCINE_MODERNA_2";
const SERVICE_CODE_COVID_19_VACCINE_JNJ = "COVID_19_VACCINE_JNJ";

let SERVICE_CODE = {};

SERVICE_CODE[SERVICE_CODE_COVID_19_TEST] = {
  label: "Covid-19 Test"
};

SERVICE_CODE[SERVICE_CODE_FLU_SHOT] = {
  label: "Flu Shot"
};

SERVICE_CODE[SERVICE_CODE_CONSULT] = {
  label: "Consultation"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VAX] = {
  label: "Covid-19 Vax"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VACCINE_PFIZER_1] = {
  label: "Covid-19 Vax - Dose 1 (Pfizer)"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VACCINE_PFIZER_2] = {
  label: "Covid-19 Vax - Dose 2 (Pfizer)"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VACCINE_MODERNA_1] = {
  label: "Covid-19 Vax - Dose 1 (Moderna)"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VACCINE_MODERNA_2] = {
  label: "Covid-19 Vax - Dose 2 (Moderna)"
};

SERVICE_CODE[SERVICE_CODE_COVID_19_VACCINE_JNJ] = {
  label: "Covid-19 Vax - Johnson & Johnson"
};

export {
  SERVICE_CODE_COVID_19_TEST,
  SERVICE_CODE_FLU_SHOT,
  SERVICE_CODE_CONSULT,
  SERVICE_CODE_COVID_19_VAX,
  SERVICE_CODE_COVID_19_VACCINE_PFIZER_1,
  SERVICE_CODE_COVID_19_VACCINE_PFIZER_2,
  SERVICE_CODE_COVID_19_VACCINE_MODERNA_1,
  SERVICE_CODE_COVID_19_VACCINE_MODERNA_2,
  SERVICE_CODE_COVID_19_VACCINE_JNJ,
  SERVICE_CODE
};
